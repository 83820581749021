import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/api.services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ownhorsedetails',
  templateUrl: './ownhorsedetails.component.html',
  styleUrl: './ownhorsedetails.component.css'
})
export class OwnhorsedetailsComponent implements OnInit {

  horseData: any = {};

  constructor(private callApi: UserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res: any) => {
      let payload = {
        wallet_address: this.callApi.getAddress(),
        id: res?.id
      }
      this.callApi.userHorse(payload).subscribe((res: any) => {
        this.horseData = res?.data[0]
      })
    })
  }

}
