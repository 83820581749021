<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <form [formGroup]="form" autocomplete="off" class="glass-card p-sm-4 p-2 text-center">
                    <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">For Security</h1>
                    <div class="mb-4 ">
                        <input type="text" class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            id="name" formControlName="user_name" placeholder="Enter Name">
                        <div *ngIf="submitted && f['user_name']?.errors" class="text-danger">
                            <div *ngIf="f['user_name'].errors?.['required']"> Username Field is Required</div>
                        </div>
                    </div>
                    <div class="mb-4 ">
                        <input class="ph-color form-control bg-dark border-color py-3 shadow text-white"
                            id="inputPassword" placeholder="Enter your Date of Birth" type="date" formControlName="dob">
                        <div *ngIf="submitted && f['dob']?.errors" class="text-danger">
                            <div *ngIf="f['dob'].errors?.['required']"> DOB Field is Required</div>
                        </div>
                    </div>
                    <div class="mb-4 ">
                        <input class="ph-color form-control bg-dark border-color py-3 shadow text-white"
                            id="inputPassword" placeholder="Enter your Phone Number" formControlName="mobile_number"
                            type="number">
                        <div *ngIf="submitted && f['mobile_number']?.errors" class="text-danger">
                            <div *ngIf="f['mobile_number'].errors?.['required']"> phone number Field is Required</div>
                        </div>
                    </div>
                    <div class="mb-4 ">
                        <label for="">Upload Image <span class="text-danger">*</span> </label>
                        <div class="">
                            <label for="image">
                                <img *ngIf="!userimage" style="cursor: pointer;" class="img-fluid"
                                    src="assets/img/profile.png" width="100px" height="100px">
                                <img *ngIf="userimage" style="cursor: pointer;" class="img-fluid"
                                    [src]="imagepath+userimage" width="100px" height="100px">
                            </label>
                            <input id="image" formControlName="image" hidden type="file" accept="application/*|image/*"
                                (change)="uploadImage($event)">
                            <div *ngIf="submitted && f['image']?.errors" class="text-danger">
                                <div *ngIf="f['image'].errors?.['required']"> image Field is Required </div>
                            </div>
                        </div>
                        <small class="text-info">Upload Your Kyc Document * {{profileData?.id_type}}</small>
                    </div>
                    <a (click)="submit()" class="btn px-5 py-3 fw-bold btn-theme w-100" type="submit">Submit</a>
                </form>
            </div>
        </div>
    </div>
</section>