<section>
    <video autoplay muted loop class="w-100 top-0 z-n1 position-absolute" style="object-fit: cover; filter: brightness(0.5); min-height: 100vh;"
    oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="assets/img/horse.mp4" type="video/mp4" style="background-color: #333333;">
    </video>
    <div class="container-fluid px-sm-5">
        <div class="row px-sm-5 px-2 justify-content-center align-items-center animate__animated animate__fadeIn animate__delay-3s" style="min-height: 87vh;">
            <div class="col-xxl-6 col-xl-8 col-sm-10 text-center">
                <h1 class="display-1 fw-bold theme-gradient">Own Champion Race Horses</h1>
                <p class="fs-4 text-white fw-medium my-4">Build your virtual stable of champions and use your skill to dominate on the racetrack against global competitors for big rewards.</p>
                <a routerLink="/login" class="btn px-5 py-2 fw-bold btn-theme">Play Now</a>
            </div>
        </div>
    </div>
</section>