<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">Email Verification</h1>
                <form [formGroup]="form" class="glass-card p-sm-4 p-2 text-center">
                    <h3 class="fw-bold theme-gradient mb-4 w-f mx-auto">Verification Code</h3>
                    <div class="mb-4 ">
                        <input type="text" class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            placeholder="Enter OTP" (keypress)="keyPressNumbers($event)" maxlength="6"
                            formControlName="otp">
                        <div class="text-danger" *ngIf="submitted && f['otp'].errors">
                            <div *ngIf="f['otp'].errors?.['required']">Otp Required</div>
                            <div *ngIf="f['otp'].errors?.['pattern']">Invalid Otp</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-evenly">
                        <a class="btn fw-bold btn-theme" (click)="submit()">
                            Submit
                        </a>
                        <a class="btn fw-bold btn-theme ms-2" (click)="resendOTP()">
                            <i class="fa me-2" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{button}}
                        </a>
                        <a class="btn fw-bold btn-theme ms-2" (click)="back()">
                            <i class="fa fa-long-arrow-left"></i> 
                            Back?
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>