<section class="py-80 pt-4 px-xxl-5">
    <div class="container-fluid px-xxl-5">
        <div class="row align-items-center">
            <div class="col">
                <h2 class="text-white">{{winnersList?.track_name}}</h2>
                <span class="text-theme">{{winnersList?.country_code +'.'+ winnersList?.venue}}</span>
            </div>
            <div class="col text-end">
                <span class="text-theme fs-5 fw-bold me-3"> <img src="assets/img/live.png" width="32" alt="">
                    LIVE</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-xl-3">
                <div class="row align-items-center mt-2"
                    *ngFor="let data of winnersList?.Registerd_horse;let i = index;">
                    <div class="col-auto">
                        <span class="p-2 rounded-2"
                            [ngClass]="i+1==1?'bg-gold':i+1==2?'bg-silver':i+1==3?'bg-brown':'bg-secondary'">{{'#'+(i+1)}}</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img [src]="data?.image" class="border bg-dark border-secondary rounded-start-4"
                                            width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">{{data?.horsename}}<a href=""><img
                                                    src="" alt=""></a></h5>
                                    </div>
                                    <!-- <div class="col text-center">
                                        <span class="text-theme  fs-5">{{data?.position}}</span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-silver p-2 rounded-2">#2</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_2.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Exterminator<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#12</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-brown p-2 rounded-2">#3</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_3.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Secretariat<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#4</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#4</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_4.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Seattle Slew<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#5</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_5.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Man o’ War<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#6</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_6.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Citation<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#7</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_1.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Red Rum<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#8</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_2.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Seabiscuit<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#6</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#9</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_3.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Damascus<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#7</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#10</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_4.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Lawyer Ron<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#8</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#11</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_5.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Serena’s Song<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#9</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-2">
                    <div class="col-auto">
                        <span class="bg-secondary p-2 rounded-2">#12</span>
                    </div>
                    <div class="col">
                        <div class="glass-card">
                            <div class="bg-dark rounded-4">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img src="assets/img/horse_6.webp"
                                            class="border bg-dark border-secondary rounded-start-4" width="56" alt="">
                                    </div>
                                    <div class="col-auto">
                                        <h5 class="fw-bold text-white mb-0 fs-6">Lava Man<a href=""><img src=""
                                                    alt=""></a></h5>
                                    </div>
                                    <div class="col text-center">
                                        <span class="text-theme  fs-5">#11</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-xl-9">
                <div class="glass-card overflow-hidden">
                    <div class="autoscrollable-wrapper scroll-parent pe-none">
                        <ng-container *ngFor="let data of distance">
                            <div class="scroll-element primary" [ngClass]="'mt-'+data?.margin">
                                <hr>
                                <span class="text-theme ms-3">{{data?.meter+"m"}}</span>
                            </div>
                        </ng-container>
                        <!-- <div class="scroll-element primary mt-25" >
                            <hr>
                            <span class="text-theme ms-3">2500m</span>
                        </div>
                        <div class="scroll-element primary mt-50" >
                            <hr>
                            <span class="text-theme ms-3">2400m</span>
                        </div>
                        <div class="scroll-element primary mt-75" >
                            <hr>
                            <span class="text-theme ms-3">2300m</span>
                        </div>
                        <div class="scroll-element primary mt-100" >
                            <hr>
                            <span class="text-theme ms-3">2200m</span>
                        </div>
                        <div class="scroll-element primary mt-125" >
                            <hr>
                            <span class="text-theme ms-3">2100m</span>
                        </div>
                        <div class="scroll-element primary mt-150" >
                            <hr>
                            <span class="text-theme ms-3">2000m</span>
                        </div>
                        <div class="scroll-element primary mt-175" >
                            <hr>
                            <span class="text-theme ms-3">1900m</span>
                        </div>
                        <div class="scroll-element primary mt-200" >
                            <hr>
                            <span class="text-theme ms-3">1800m</span>
                        </div>
                        <div class="scroll-element primary mt-225" >
                            <hr>
                            <span class="text-theme ms-3">1700m</span>
                        </div>
                        <div class="scroll-element primary mt-250" >
                            <hr>
                            <span class="text-theme ms-3">1600m</span>
                        </div>
                        <div class="scroll-element primary mt-275" >
                            <hr>
                            <span class="text-theme ms-3">1500m</span>
                        </div>
                        <div class="scroll-element primary mt-300">
                            <hr>
                            <span class="text-theme ms-3">1400m</span>
                        </div>
                        <div class="scroll-element primary mt-325" >
                            <hr>
                            <span class="text-theme ms-3">1300m</span>
                        </div>
                        <div class="scroll-element primary mt-350" >
                            <hr>
                            <span class="text-theme ms-3">1200m</span>
                        </div>
                        <div class="scroll-element primary mt-375" >
                            <hr>
                            <span class="text-theme ms-3">1100m</span>
                        </div>
                        <div class="scroll-element primary mt-400">
                            <hr>
                            <span class="text-theme ms-3">1000m</span>
                        </div>
                        <div class="scroll-element primary mt-425">
                            <hr>
                            <span class="text-theme ms-3">900m</span>
                        </div>
                        <div class="scroll-element primary mt-450">
                            <hr>
                            <span class="text-theme ms-3">800m</span>
                        </div>
                        <div class="scroll-element primary mt-475">
                            <hr>
                            <span class="text-theme ms-3">700m</span>
                        </div>
                        <div class="scroll-element primary mt-500">
                            <hr>
                            <span class="text-theme ms-3">600m</span>
                        </div>
                        <div class="scroll-element primary mt-525">
                            <hr>
                            <span class="text-theme ms-3">500m</span>
                        </div>
                        <div class="scroll-element primary mt-550">
                            <hr>
                            <span class="text-theme ms-3">400m</span>
                        </div>
                        <div class="scroll-element primary mt-575">
                            <hr>
                            <span class="text-theme ms-3">300m</span>
                        </div>
                        <div class="scroll-element primary mt-600">
                            <hr>
                            <span class="text-theme ms-3">200m</span>
                        </div>
                        <div class="scroll-element primary mt-625">
                            <hr>
                            <span class="text-theme ms-3">100m</span>
                        </div>
                        <div class="scroll-element primary mt-650">
                            <hr>
                            <span class="text-theme ms-3">0m</span>
                        </div> -->
                    </div>

                </div>
                <div class="row text-center animate__animated animate__fadeInUpBig animate__slower" style="margin-top: -530px;
                position: relative;">
                    <div class="col mt-1 animate__animated animate__infinite animate__shakeY animate__slow"
                        *ngFor="let data of winnersList?.Registerd_horse;let i = index;">
                        <img [src]="data?.image" width="50" alt="">
                        <p class="text-theme">{{data?.horsename}}</p>
                    </div>
                    <!-- <div class="col mt-2 animate__animated animate__infinite animate__shakeY animate__slower">
                        <img src="assets/img/horse_color/2.webp" width="50" alt="">
                        <p class="text-theme">#2</p>
                    </div>
                    <div class="col mt-3 animate__animated animate__infinite animate__shakeY animate__fast">
                        <img src="assets/img/horse_color/3.webp" width="50" alt="">
                        <p class="text-theme">#3</p>
                    </div>
                    <div class="col mt-4 animate__animated animate__infinite animate__shakeY animate__faster">
                        <img src="assets/img/horse_color/4.webp" width="50" alt="">
                        <p class="text-theme">#4</p>
                    </div>
                    <div class="col mt-5 animate__animated animate__infinite animate__shakeY animate__slow">
                        <img src="assets/img/horse_color/5.webp" width="50" alt="">
                        <p class="text-theme">#5</p>
                    </div>
                    <div class="col mt-4 animate__animated animate__infinite animate__shakeY animate__slower">
                        <img src="assets/img/horse_color/6.webp" width="50" alt="">
                        <p class="text-theme">#6</p>
                    </div>
                    <div class="col mt-3 animate__animated animate__infinite animate__shakeY animate__slow">
                        <img src="assets/img/horse_color/7.webp" width="50" alt="">
                        <p class="text-theme">#7</p>
                    </div>
                    <div class="col mt-2 animate__animated animate__infinite animate__shakeY animate__slower">
                        <img src="assets/img/horse_color/8.webp" width="50" alt="">
                        <p class="text-theme">#8</p>
                    </div>
                    <div class="col mt-1 animate__animated animate__infinite animate__shakeY animate__slow">
                        <img src="assets/img/horse_color/9.webp" width="50" alt="">
                        <p class="text-theme">#9</p>
                    </div>
                    <div class="col mt-0 animate__animated animate__infinite animate__shakeY animate__slower">
                        <img src="assets/img/horse_color/10.webp" width="50" alt="">
                        <p class="text-theme">#10</p>
                    </div>
                    <div class="col mt-5 animate__animated animate__infinite animate__shakeY animate__slow">
                        <img src="assets/img/horse_color/11.webp" width="50" alt="">
                        <p class="text-theme">#11</p>
                    </div>
                    <div class="col mt-3 animate__animated animate__infinite animate__shakeY animate__slower">
                        <img src="assets/img/horse_color/12.webp" width="50" alt="">
                        <p class="text-theme">#12</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #winnerTab>
    <section class="pb-4 pt-4">
        <div class="container-fluid">
            <div class="row">
                <h6 class="winner-head text-center">WINNER IS</h6>
                <div class="d-flex justify-content-evenly mt-4 winner-data">
                    <img [src]="firstWinner?.image" class="img-fluid" height="150">
                    <div class="row align-items-center glass-card ps-3 pe-3">
                        <ul class="winning-category list-unstyled">
                            <li><span>Position</span> <label
                                    class="pt-1 pb-1 ps-3 pe-4 rounded-2 bg-gold position-num">1st</label></li>
                            <li><span>Horse Name</span> {{firstWinner?.horsename}}</li>
                            <li><span>Distance</span> {{winnersList?.Distance+'m'}}</li>
                        </ul>
                    </div>
                </div>
                <div class="text-center pt-5">
                    <button class="btn px-5 py-2 fw-bold btn-theme" (click)="backToRace()">GO to result</button>
                </div>
            </div>
        </div>
    </section>
</ng-template>