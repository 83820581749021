import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserService } from '../../services/api.services';
import { environment } from '../../../environments/environment.development';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {

  data: any;
  profileImg: any;
  viewImg: any;
  workmail: any;
  workGoogle: any;
  copyvalue: any;
  copy: boolean = false
  button = 'Enable'
  isLoading: boolean = false
  copiedValues: string = 'Click to copy link';
  enableForm: any;
  mailOTP: any;

  constructor(private callApi: UserService, private dialog: MatDialog, private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.getCommonData();
    this.createForm()
  }

  getCommonData() {
    this.callApi.getProfile().subscribe((res: any) => {
      if (res?.success) {
        this.data = res?.data
        this.viewImg = environment?.IMAGE_URL + this.data?.userDeatils?.profile_img
      }
    })
  }

  upload(event: any) {
    this.profileImg = event.target.files[0]
    let formData = new FormData();
    formData.append('profile_image', this.profileImg)
    this.callApi.uploadImgProfile(formData).subscribe((res: any) => {
      if (res.success == true) {
        this.callApi.showSuccess(res.message)
        let reader = new FileReader();
        reader.onload = ((image: any) => {
          this.viewImg = image.target.result;
        })
        reader.readAsDataURL(event.target.files[0])
      } else {
        this.callApi.showError(res.message)
      }
    })
  }

  enable(templateRef: TemplateRef<any>, data: any) {
    if (data == 'google') {
      this.workmail = 'Google'
      this.dialog.open(templateRef, {
        panelClass: 'disable',
        disableClose: true,
        height: 'auto',
        width: '40%',
        data: data,
      })
    }
    if (data == 'Email') {
      this.callApi.emailAuthentication().subscribe((res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
        }
        else {
          this.callApi.showError(res.message)
        }
      })
      this.workmail = 'Email'
      this.dialog.open(templateRef, {
        panelClass: 'disable',
        disableClose: true,
        height: 'auto',
        width: '35%',
        data: data
      })
    }
  }

  getEmailOtp() {
    this.button = 'Processing'
    this.isLoading = true
    this.callApi.emailAuthentication().subscribe((res: any) => {
      if (res?.success === true) {
        this.router.navigate(['/email-otp'])
        this.callApi.showSuccess(res.message)
        this.isLoading = false
        this.button = 'Enable'
      } else {
        this.callApi.showError(res.message)
        this.isLoading = false
        this.button = 'Enable'
      }
    })
  }

  copyButton() {
    this.copy = true,
      this.copiedValues = 'Copied'
    setTimeout(() => {
      this.copy = false,
        this.copiedValues = 'Click to copy link'
    }, 3000)
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  createForm() {
    this.enableForm = this.fb.group({
      otp: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      status: ["2"]
    })
  }

  backEnable(type: any) {
    if (type == 'Google') {
      this.callApi.checkTfa(this.enableForm.value).subscribe((res: any) => {
        if (res.success == true) {
          this.dialog.closeAll();
          this.callApi.showSuccess(res.message)
          this.data.userDeatils.authentic_status = '0'
        }
        else {
          this.callApi.showError(res.message)
        }
      })
    }
    if (type == 'Email') {
      let payload = {
        status: "2",
        otp: this.mailOTP
      }
      this.callApi.disablemailauthentication(payload).subscribe((res: any) => {
        if (res.success == true) {
          this.data.userDeatils.authentic_status = '0'
          this.dialog.closeAll();
          this.callApi.showSuccess(res.message)
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }
}
