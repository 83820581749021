<section class="py-80">
    <div class="container-md container-fluid">
        <h2 class="text-theme mb-0">Breed horse</h2>
        <div class="row mt-3">
            <div class="col-3">
                <input type="text" class="form-control bg-transparent text-theme" placeholder="" value="Search"
                    aria-label="First name">
            </div>
            <div class="col">
                <div class="dropdown">
                    <button class="btn btn-secondary bg-transparent dropdown-toggle" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Filter
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                    <span class="ms-3 text-success">Clear all</span>
                </div>
            </div>
            <div class="col-3">
                <select class="form-select bg-transparent text-theme" aria-label="Default select example">
                    <option selected>Sort By</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
        </div>
        <hr class="border-white">
        <div class="glass-card p-4 mt-4">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="d-flex">
                        <img src="assets/img/horse_1.webp" class="me-4 border bg-dark border-secondary" width="128"
                            alt="">
                        <div class="w-100">
                            <h5 class="fw-bold text-white mb-0 fs-4">True Blue Stable<a href=""><img src="" alt=""></a>
                            </h5>
                            <ul class="text-theme list-unstyled mt-2">
                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                <li> Gender : <span class="text-white"> stallion</span></li>
                                <li> Coat : <span class="text-white"> Classic</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 text-end">
                    <a routerLink="/select-breed" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                </div>
            </div>
        </div>

        <div class="glass-card p-4 mt-4">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex">
                        <img src="assets/img/horse_4.webp" class="me-4 border bg-dark border-secondary" width="128"
                            alt="">
                        <div class="w-100">
                            <h5 class="fw-bold text-white mb-0 fs-4">Apollo<a href=""><img src="" alt=""></a></h5>
                            <ul class="text-theme list-unstyled mt-2">
                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                <li> Gender : <span class="text-white"> stallion</span></li>
                                <li> Coat : <span class="text-white"> Classic</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <a routerLink="/select-breed" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                </div>
            </div>
        </div>
        <div class="glass-card p-4 mt-4">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex">
                        <img src="assets/img/horse_2.webp" class="me-4 border bg-dark border-secondary" width="128"
                            alt="">
                        <div class="w-100">
                            <h5 class="fw-bold text-white mb-0 fs-4">Big Brown<a href=""><img src="" alt=""></a></h5>
                            <ul class="text-theme list-unstyled mt-2">
                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                <li> Gender : <span class="text-white"> stallion</span></li>
                                <li> Coat : <span class="text-white"> Classic</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <a routerLink="/select-breed" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                </div>
            </div>
        </div>
        <div class="glass-card p-4 mt-4">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex">
                        <img src="assets/img/horse_3.webp" class="me-4 border bg-dark border-secondary" width="128"
                            alt="">
                        <div class="w-100">
                            <h5 class="fw-bold text-white mb-0 fs-4">Gallant Fox<a href=""><img src="" alt=""></a></h5>
                            <ul class="text-theme list-unstyled mt-2">
                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                <li> Gender : <span class="text-white"> stallion</span></li>
                                <li> Coat : <span class="text-white"> Classic</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <a routerLink="/select-breed" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                </div>
            </div>
        </div>
        <div class="mt-4 text-center">
            <a href="" class="btn px-5 py-2 fw-bold btn-theme">load more</a>
        </div>
    </div>
</section>