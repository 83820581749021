<ngx-spinner type="cog" size="medium"></ngx-spinner>
<section>
    <div class="container-fluid px-sm-5">
        <div class="col-xxl-8 col-10 mx-auto">
            <div class="row px-sm-5 px-2 justify-content-xxl-evenly justify-content-end align-items-center glass-card py-3">                                                        
                <div class="col-lg-3 order-lg-1 order-2 text-lg-start text-center">
                    <h3 class="text-white text-center mb-3">{{horseData?.name}}</h3>
                    <div class="row bg-dark p-3 rounded">  
                        <div class="col-auto">
                            <img src="assets/img/diamond.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Generation</p>
                            <p class="text-white mb-0">First Generation</p>
                        </div>                                    
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">  
                        <div class="col-auto">
                            <img src="assets/img/blood.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Bloodline</p>
                            <p class="text-white mb-0">UCHIKA</p>
                        </div>                                    
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">  
                        <div class="col-auto">
                            <img src="assets/img/gender.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Gender</p>
                            <p class="text-white mb-0">{{horseData?.gender}}</p>
                        </div>                                    
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">  
                        <div class="col-auto">
                            <img src="assets/img/palette.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Coat</p>
                            <p class="text-white mb-0">Classic</p>
                        </div>                                    
                    </div>                                                              
                    <a (click)="buyHorse()" class="btn px-5 py-2 fw-bold btn-theme mt-4">BUY</a>
                </div>
                <div class="col-xxl-9 col text-end order-lg-2 order-1">
                    <img [src]="horseData?.image_url" class="img-fluid" alt="Horse">                            
                </div>
            </div>
            <div class="text-center mt-3">
                <a routerLink="/dashboard" class="text-white me-4">Skip</a>
                <a routerLink="/choose-horse" class="text-white me-4">Back</a>
            </div>                        
        </div>                    
    </div>
</section>