<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <form [formGroup]="form" class="glass-card p-sm-4 p-2 text-center">
                    <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">I forgot my password</h1>
                    <!-- Befor Otp -->

                    <div class="mb-3" *ngIf="!responce">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white" type="email"
                            placeholder="Enter your email" formControlName="email"
                            (keypress)="keypressValidation($event)">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['email']?.errors?.['required']">Email Field is Required</div>
                            <div *ngIf="f['email']?.errors?.['pattern']">Invalid Email</div>
                        </div>
                    </div>

                    <!-- After Otp -->
                    <div class="mb-3" *ngIf="responce">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white" type="email"
                            placeholder="Enter your email" formControlName="email" >
                    </div>
                    <div class="mb-3" *ngIf="responce">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white" type="text"
                            placeholder="Enter OTP" formControlName="otp">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['otp']?.errors?.['required']">OTP Field is Required</div>
                        </div>
                    </div>
                    <div class="mb-3 pass" *ngIf="responce">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            placeholder="Enter New Password" formControlName="password"
                            [type]="showPassword ? 'text' : 'password'">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['password'].errors?.['required']">Password Field is Required</div>
                            <div class="errors"
                                *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                <div *ngIf="form.get('password').errors.minlength">
                                    Password must be at least 8 characters
                                </div>

                                <div class="error-text"
                                    *ngIf="form.get('password').hasError('passwordStrength')">
                                    {{form.get('password').errors['passwordStrength']}}
                                </div>
                            </div>
                        </div>
                        <div class="password__show">
                            <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword"
                                [hidden]="!showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                                (click)="showPassword = !showPassword" [hidden]="showPassword"></i>
                        </div>
                    </div>
                    <div class="mb-3 pass" *ngIf="responce">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            placeholder="Enter Confirm Password" (keyup)="validConfirmPassword($event)" formControlName="confirmPassword"
                            [type]="confirmPassword ? 'text' : 'password'">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f.confirmPassword.errors.mustMatch" class="text-danger">Mismatch password</div>
                        </div>
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['confirmPassword'].errors?.['required']">Confirm password Field is Required
                            </div>
                        </div>
                        <div class="password__show">
                            <i alt="show" class="far fa-eye eye-show"
                                (click)="confirmPassword = !confirmPassword"
                                [hidden]="!confirmPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                                (click)="confirmPassword = !confirmPassword" [hidden]="confirmPassword"></i>
                        </div>
                    </div>

                    <a *ngIf="!responce" (click)="submit()" class="btn px-5 py-3 fw-bold btn-theme w-100">
                        <i class="fa me-1" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                        {{button}}
                    </a>
                    <div class="d-flex flex-row-reverse">
                        <a *ngIf="responce" (click)="otp()" class="btn fw-bold btn-theme ">Submit</a>
                        <a *ngIf="responce" (click)="resendOTP()" class="btn fw-bold btn-theme me-2">
                            <i class="fa me-1" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                            {{resend}}
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>