
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.development';
import { UserService } from '../../services/api.services';

@Component({
  selector: 'app-tfalosting',
  templateUrl: './tfalosting.component.html',
  styleUrls: ['./tfalosting.component.scss']
})
export class TfalostingComponent implements OnInit {

  form: any
  userimage: any
  public imagepath = environment.IMAGE_URL
  image: any;
  submitted: boolean = false
  profileData: any;

  constructor(private callApi: UserService, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.createForm()
    this.callApi.getProfile().subscribe((res: any) => {
      this.profileData = res?.data?.TwofaLostkycDetails
    })
  }

  createForm() {
    this.form = this.fb.group({
      user_name: ['', [Validators.required]],
      dob: ['', Validators.required],
      mobile_number: ['', Validators.required],
      image: ['', Validators.required]
    })
  }

  get f() { return this.form.controls }

  submit() {
    this.submitted = true
    if (this.form.valid && this.userimage) {
      let payload = this.form.value
      payload.image = this.userimage
      this.callApi.twofaLostingRequest(this.form.value).subscribe((res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
          this.form.reset()
          this.userimage = ''
          sessionStorage.clear()
          this.router.navigate(['/login'])
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }

  uploadImage(event: any) {
    this.image = event.target.files[0]
    let fileSize = 10 * 1024 * 1024;
    let formData = new FormData()
    formData.append('image', this.image)
    if (fileSize > this.image.size) {
      this.callApi.twofaLostImage(formData).subscribe((res: any) => {
        if (res.success == true) {
          this.userimage = res?.data
        } else {
          this.callApi.showError(res.message)
        }
      })
    } else {
      this.callApi.showError("Image size should be less than 10 MB")
    }

  }
  stringValidation(event: any) {
    if (!event.key.match(/^[a-zA-Z]+$/)) {
      event.preventDefault();
    }
  }
}

