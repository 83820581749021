<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <form [formGroup]="form" autocomplete="off" class="glass-card p-sm-4 p-2 text-center">
                    <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">Sign In</h1>
                    <!-- <p class="text-white fw-bold">Continue with:</p>
                    <div class="row">
                        <div class="col">
                            <a href="javascript:void(0)" class="bg-dark rounded-4 p-3 w-100 d-block">
                                <img src="assets/img/google.png" alt="">
                            </a>
                        </div>
                        <div class="col">
                            <a href="javascript:void(0)" class="bg-dark rounded-4 p-3 w-100 d-block">
                                <img src="assets/img/metamask.png" alt="">
                            </a>
                        </div>
                    </div>
                    <hr class="mt-4">
                    <p class="bg-dark text-white p-2 rounded-circle mt-n3 w-f mx-auto">OR</p> -->
                    <div class="mb-4 ">
                        <input type="text" class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            id="name" placeholder="Enter your email" maxlength="60" formControlName="email"
                            (keypress)="keyPressNumbers($event)">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['email']?.errors?.['required']">Username or email Field is Required</div>
                            <div *ngIf="f['email']?.errors?.['minlength']">Enter valid username or email</div>
                        </div>
                    </div>
                    <div class="mb-4 ">
                        <div class="pass">
                            <input type="password"
                                class="ph-color form-control bg-dark border-color py-3 shadow text-white"
                                id="inputPassword" placeholder="Enter your password" maxlength="30"
                                formControlName="password" (keyup)="keyPressNumbers($event)"
                                [type]="showPassword ? 'text' : 'password'">
                            <div *ngIf="submitted" class="text-danger">
                                <div *ngIf="f['password'].errors?.['required']">Password Field is Required</div>
                            </div>
                            <div class="password__show">
                                <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword"
                                    [hidden]="!showPassword"></i>
                                <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                                    [hidden]="showPassword"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-check text-start my-3 d-flex">
                        <a routerLink="/forgotpassword" class="text-center mb-0 ms-auto theme-gradient fw-bold">I forget
                            my
                            password</a>
                    </div>
                    <button (click)="submit()" class="btn px-5 py-3 fw-bold btn-theme w-100" type="submit">
                        <i class="fa me-2" *ngIf="isLoading" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                        {{button}}
                    </button>
                </form>
                <p class="mt-3 text-white text-center">Don't have an account? <a class="theme-gradient fw-bold"
                        routerLink="/register">SignUp</a> </p>
            </div>
        </div>
    </div>
</section>