import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/api.services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-startrace',
  templateUrl: './startrace.component.html',
  styleUrl: './startrace.component.css'
})
export class StartraceComponent implements OnInit {

  winnersList: any;
  distance: any = []
  firstWinner: any;
  @ViewChild('winnerTab') winnerTab: any;
  @HostBinding('style.--target-width')
  private targetWidth: string = '';
  raceId: any;

  constructor(private callApi: UserService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe((res: any) => {
      this.raceId = res?.id
      this.raceWinner(res?.id)
    })
  }

  raceWinner(id: any) {
    this.callApi.liveRace({ id: id }).subscribe((res: any) => {
      this.winnersList = res?.data[0]
      let data: number;
      this.winnersList?.Distance == 1000 ? (data = 250, this.targetWidth = '-500%') : this.winnersList?.Distance == 1200 ? (data = 300, this.targetWidth = '-600%') :
        this.winnersList?.Distance == 1400 ? (data = 350, this.targetWidth = '-700%') : this.winnersList?.Distance == 1600 ? (data = 400, this.targetWidth = '-800%') :
          this.winnersList?.Distance == 1800 ? (data = 450, this.targetWidth = '-900%') : this.winnersList?.Distance == 2000 ? (data = 500, this.targetWidth = '-1000%') :
            this.winnersList?.Distance == 2200 ? (data = 550, this.targetWidth = '-1100%') : this.winnersList?.Distance == 2400 ? (data = 600, this.targetWidth = '-1200%') :
              (data = 650, this.targetWidth = '-1300%')
      for (let i = 0; i <= this.winnersList?.Distance; i = i + 100) {
        this.distance.push({ meter: i, margin: data })
        data = data - 25
      }
      this.distance.reverse()
      this.winnerCalculation()
    })
  }

  async winnerCalculation() {
    setTimeout(async () => {
      await this.winnersList?.Registerd_horse?.map((item: any) => {
        if (item?.position == 'one') {
          this.firstWinner = item
        }
      })
      this.dialog.open(this.winnerTab, {
        width: '50%',
        height: 'auto',
        disableClose: true
      })
    }, 20300) //20300
  }

  backToRace() {
    this.callApi.completeLiveRace({ id: this.raceId }).subscribe((res: any) => {
      this.dialog.closeAll()
      this.router.navigate(['/race-details'], { queryParams: { tab: 'Winnings-tab' } })
    })
  }

}
