export default {
    "name": "EIP-2537",
    "number": 2537,
    "comment": "BLS12-381 precompiles",
    "url": "https://eips.ethereum.org/EIPS/eip-2537",
    "status": "Draft",
    "minimumHardfork": "chainstart",
    "gasConfig": {},
    "gasPrices": {
        "Bls12381G1AddGas": {
            "v": 600,
            "d": "Gas cost of a single BLS12-381 G1 addition precompile-call"
        },
        "Bls12381G1MulGas": {
            "v": 12000,
            "d": "Gas cost of a single BLS12-381 G1 multiplication precompile-call"
        },
        "Bls12381G2AddGas": {
            "v": 4500,
            "d": "Gas cost of a single BLS12-381 G2 addition precompile-call"
        },
        "Bls12381G2MulGas": {
            "v": 55000,
            "d": "Gas cost of a single BLS12-381 G2 multiplication precompile-call"
        },
        "Bls12381PairingBaseGas": {
            "v": 115000,
            "d": "Base gas cost of BLS12-381 pairing check"
        },
        "Bls12381PairingPerPairGas": {
            "v": 23000,
            "d": "Per-pair gas cost of BLS12-381 pairing check"
        },
        "Bls12381MapG1Gas": {
            "v": 5500,
            "d": "Gas cost of BLS12-381 map field element to G1"
        },
        "Bls12381MapG2Gas": {
            "v": 110000,
            "d": "Gas cost of BLS12-381 map field element to G2"
        },
        "Bls12381MultiExpGasDiscount": {
            "v": [
                [1, 1200],
                [2, 888],
                [3, 764],
                [4, 641],
                [5, 594],
                [6, 547],
                [7, 500],
                [8, 453],
                [9, 438],
                [10, 423],
                [11, 408],
                [12, 394],
                [13, 379],
                [14, 364],
                [15, 349],
                [16, 334],
                [17, 330],
                [18, 326],
                [19, 322],
                [20, 318],
                [21, 314],
                [22, 310],
                [23, 306],
                [24, 302],
                [25, 298],
                [26, 294],
                [27, 289],
                [28, 285],
                [29, 281],
                [30, 277],
                [31, 273],
                [32, 269],
                [33, 268],
                [34, 266],
                [35, 265],
                [36, 263],
                [37, 262],
                [38, 260],
                [39, 259],
                [40, 257],
                [41, 256],
                [42, 254],
                [43, 253],
                [44, 251],
                [45, 250],
                [46, 248],
                [47, 247],
                [48, 245],
                [49, 244],
                [50, 242],
                [51, 241],
                [52, 239],
                [53, 238],
                [54, 236],
                [55, 235],
                [56, 233],
                [57, 232],
                [58, 231],
                [59, 229],
                [60, 228],
                [61, 226],
                [62, 225],
                [63, 223],
                [64, 222],
                [65, 221],
                [66, 220],
                [67, 219],
                [68, 219],
                [69, 218],
                [70, 217],
                [71, 216],
                [72, 216],
                [73, 215],
                [74, 214],
                [75, 213],
                [76, 213],
                [77, 212],
                [78, 211],
                [79, 211],
                [80, 210],
                [81, 209],
                [82, 208],
                [83, 208],
                [84, 207],
                [85, 206],
                [86, 205],
                [87, 205],
                [88, 204],
                [89, 203],
                [90, 202],
                [91, 202],
                [92, 201],
                [93, 200],
                [94, 199],
                [95, 199],
                [96, 198],
                [97, 197],
                [98, 196],
                [99, 196],
                [100, 195],
                [101, 194],
                [102, 193],
                [103, 193],
                [104, 192],
                [105, 191],
                [106, 191],
                [107, 190],
                [108, 189],
                [109, 188],
                [110, 188],
                [111, 187],
                [112, 186],
                [113, 185],
                [114, 185],
                [115, 184],
                [116, 183],
                [117, 182],
                [118, 182],
                [119, 181],
                [120, 180],
                [121, 179],
                [122, 179],
                [123, 178],
                [124, 177],
                [125, 176],
                [126, 176],
                [127, 175],
                [128, 174]
            ],
            "d": "Discount gas costs of calls to the MultiExp precompiles with `k` (point, scalar) pair"
        }
    },
    "vm": {},
    "pow": {}
};
