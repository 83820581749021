import { Component } from '@angular/core';

@Component({
  selector: 'app-selectbreedpair',
  templateUrl: './selectbreedpair.component.html',
  styleUrl: './selectbreedpair.component.css'
})
export class SelectbreedpairComponent {

}
