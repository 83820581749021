import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './common/layout/layout.component';
import { LoginComponent } from './common/login/login.component';
import { RegisterComponent } from './common/register/register.component';
import { ForgetpasswordComponent } from './common/forgetpassword/forgetpassword.component';
import { LandingComponent } from './common/landing/landing.component';
import { SelecthorseComponent } from './component/selecthorse/selecthorse.component';
import { HorsedetailsComponent } from './component/horsedetails/horsedetails.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { SettingsComponent } from './component/settings/settings.component';
import { OwnhorsedetailsComponent } from './component/ownhorsedetails/ownhorsedetails.component';
import { RacedetailsComponent } from './component/racedetails/racedetails.component';
import { StartraceComponent } from './component/startrace/startrace.component';
import { HorsebreedComponent } from './component/horsebreed/horsebreed.component';
import { SelectbreedpairComponent } from './component/selectbreedpair/selectbreedpair.component';
import { HeaderComponent } from './common/header/header.component';
import { StartbreedComponent } from './component/startbreed/startbreed.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyOtpComponent } from './common/verify-otp/verify-otp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TfaverificationComponent } from './common/tfaverification/tfaverification.component';
import { KycuserComponent } from './common/kycuser/kycuser.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { WebcamModule } from 'ngx-webcam';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { EmailotpComponent } from './common/emailotp/emailotp.component';
import { UsertwofaComponent } from './common/usertwofa/usertwofa.component';
import { MatIconModule } from '@angular/material/icon';
import { TfalostingComponent } from './common/tfalosting/tfalosting.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    RegisterComponent,
    ForgetpasswordComponent,
    LandingComponent,
    SelecthorseComponent,
    HorsedetailsComponent,
    DashboardComponent,
    SettingsComponent,
    OwnhorsedetailsComponent,
    RacedetailsComponent,
    StartraceComponent,
    HorsebreedComponent,
    SelectbreedpairComponent,
    HeaderComponent,
    StartbreedComponent,
    VerifyOtpComponent,
    TfaverificationComponent,
    KycuserComponent,
    EmailotpComponent,
    UsertwofaComponent,
    TfalostingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    WebcamModule,
    MatDatepickerModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatSliderModule,
    ToastrModule.forRoot({
      timeOut: 2000
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [
    provideAnimationsAsync(),
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
