import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/api.services';
import { Router } from '@angular/router';
import { PasswordStrengthValidator } from '../../services/password-strength.validators';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrl: './forgetpassword.component.css'
})
export class ForgetpasswordComponent {

  form: any;
  button = 'Send OTP'
  resend = 'Re-Send OTP'
  isLoading = false;
  submitted: boolean = false
  responce: Boolean = false;
  confirmPass: boolean = false;
  public showPassword: boolean = false;
  public confirmPassword: boolean = false;

  constructor(private fb: FormBuilder, private callApi: UserService, private router: Router) { }

  ngOnInit(): void {
    this.createForm()
  }

  get f() { return this.form.controls; }

  createForm() {
    let emailregex: RegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(emailregex)]],
      otp: [null, [Validators.required]],
      password: [null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirmPassword: [null, [Validators.required]]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    })
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  submit() {
    this.button = 'Processing';
    this.isLoading = true;
    if (this.form.controls['email'].valid) {
      this.submitted = false
      let email = this.form.value
      this.callApi.forgetRequest({ email: email.email }).subscribe((res: any) => {

        if (res.success === true) {
          this.isLoading = false;
          this.submitted = false;
          this.callApi.showSuccess(res.message)
          this.responce = true
        }
        else {
          this.button = 'Send OTP';
          this.isLoading = false;
          this.callApi.showError(res.message)
        }
      })
    }
    else {
      this.button = 'Send OTP';
      this.isLoading = false;
      this.submitted = true
    }
  }

  validConfirmPassword(event: any) {
    if (event.target.value == this.form.controls['password'].value) {
      this.confirmPass = true
    } else {
      this.confirmPass = false;
    }
  }
  otp() {
    this.submitted = true
    if (!this.form.invalid) {
      let payload = {
        ...this.form.value
      }
      this.callApi.forgetOTP(payload).subscribe((res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/login'])
        }
        else {
          this.callApi.showError(res.message)
        }
      })
    }
  }
  keypressValidation(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode < 46 || charCode > 57 && charCode <= 63 || charCode > 64 && charCode < 65 || charCode > 90 && charCode < 95 || charCode > 122 || charCode == 47) {
      event.preventDefault();
      return false
    } else {
      return true;
    }
  }

  resendOTP() {
    this.resend = 'Processing'
    this.isLoading = true
    if (this.form.controls['email'].valid) {
      let email = this.form.value
      this.isLoading = true;
      this.submitted = false

      this.callApi.forgetRequest({ email: email.email }).subscribe((res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
          this.isLoading = false;
          this.submitted = false;
          this.resend = 'Re-Send OTP'

        } else {
          this.callApi.showError(res.message)
          this.resend = 'Re-Send OTP'
          this.isLoading = false
        }
      }, err => {
        this.resend = 'Re-Send OTP'
        this.isLoading = false
      })
    } else {
      this.resend = 'Re-Send OTP';
      this.isLoading = false;
      this.submitted = true
    }
  }
}
