<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <form [formGroup]="form" autocomplete="off"
                    class="glass-card p-sm-4 p-2 text-center">
                    <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">Sign Up</h1>
                    <div class="mb-4 ">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white" maxlength="30"
                            placeholder="Enter your Username" (keypress)="userNameValidation($event)" type="text"
                            formControlName="user_name">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['user_name'].errors?.['required']">Username Field is Required</div>
                            <div *ngIf="f['user_name'].errors?.['minlength']">Username must be 3 character</div>
                            <div *ngIf="f['user_name'].errors?.['maxlength']">Username can be 30 character</div>
                        </div>
                    </div>
                    <div class="mb-4 ">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white" maxlength="60"
                            type="email" placeholder="Enter your email" (keypress)="keyPressNumbers($event)"
                            formControlName="email">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['email'].errors?.['required']">Email Field is Required</div>
                            <div *ngIf="f['email'].errors?.['pattern']">Invalid email</div>
                        </div>
                    </div>
                    <div class="mb-4 pass">
                        <input class="ph-color form-control bg-dark border-color py-3 shadow text-white" maxlength="30"
                            placeholder="Enter password" formControlName="password"
                            [type]="showPassword ? 'text' : 'password'">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['password'].errors?.['required']">Password Field is Required</div>
                            <div *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                <div *ngIf="form.get('password').errors.minlength">
                                    Password must be at least 8 characters
                                </div>

                                <div class="error-text" *ngIf="form.get('password').hasError('passwordStrength')">
                                    {{form.get('password').errors['passwordStrength']}}
                                </div>
                            </div>
                        </div>
                        <div class="password__show">
                            <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword"
                                [hidden]="!showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                                [hidden]="showPassword"></i>
                        </div>
                    </div>
                    <div class="mb-4 pass">
                        <input class="ph-color form-control bg-dark border-color py-3 shadow text-white" maxlength="30"
                            placeholder="Enter confirm password" formControlName="confirmPassword"
                            [type]="confirmPassword ? 'text' : 'password'">
                        <div *ngIf="submitted" class="text-danger">
                            <div *ngIf="f['confirmPassword'].errors?.['required']">Confirm password Field is Required
                            </div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch" class="text-danger">Mismatch password</div>
                        </div>
                        <div class="password__show">
                            <i alt="show" class="far fa-eye eye-show" (click)="confirmPassword = !confirmPassword"
                                [hidden]="!confirmPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide" (click)="confirmPassword = !confirmPassword"
                                [hidden]="confirmPassword"></i>
                        </div>
                    </div>
                    <a type="submit" (click)="submit()" class="btn px-5 py-3 fw-bold btn-theme w-100">
                        <i class="fa me-1" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                        {{button}}
                    </a>
                </form>
                <p class="mt-3 text-white text-center">Have an account? <a class="theme-gradient fw-bold"
                        routerLink="/login">SignIn</a> </p>
            </div>
        </div>
    </div>
</section>