import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/api.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  token: any;
  profile_image: any;

  constructor(private callApi: UserService, private router: Router) {
    this.callApi.subscribeEvent().subscribe((res: any) => {
      if (res?.eventName == 'Login') {
        this.token = this.callApi.getToken()
        let decryptProfileImg = this.callApi.decryptData(sessionStorage.getItem('profile-img'))
        this.profile_image = environment.IMAGE_URL + decryptProfileImg
      }
    })
  }

  ngOnInit(): void {
    this.token = this.callApi.getToken()
    let decryptProfileImg = this.callApi.decryptData(sessionStorage.getItem('profile-img'))
    this.profile_image = environment.IMAGE_URL + decryptProfileImg
  }

  logOut() {
    Swal.fire({
      title: 'Do you want to Logout ?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result?.isConfirmed) {
        this.callApi.logOut().subscribe((res: any) => {
          if (res?.success) {
            this.callApi.showSuccess(res?.message)
            sessionStorage.clear();
            this.router.navigate(['/login']);
            this.token = null;
            this.callApi.getToken();
          } else {
            this.callApi.showError(res?.message)
          }
        })
      }
    })
  }

}
