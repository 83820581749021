<section class="py-80">
    <div class="container">
        <div class="glass-card">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="p-4 rounded-4 ">
                        <h5 class="fw-bold theme-gradient mb-4 w-f mx-auto">Scan QR code on mobile</h5>
                        <div class="text-white w-75 mx-auto text-center">
                            Install google autheticator app in your mobile and scan QR Code (or) If you are unable to
                            scan
                            the QR code, please enter this code manually into the app
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 order-lg-2">
                                <div class="qr_code_div mt-2 mb-2 ">
                                    <img [src]="tfaQr?.data " class="img-fluid ">
                                </div>
                            </div>
                            <div class="col-lg-6 order-lg-1">
                                <div class=" ">
                                    <form [formGroup]="form " class="tfa-form">
                                        <div class="mt-3 mb-3 ">
                                            <h6 class="fw-bold theme-gradient w-f ">Google 2FA Secret Code</h6>
                                            <div class="input-group ">
                                                <input type="text" formControlName="secrectkey"
                                                    [value]="tfa" readonly class="shadow p-2 rounded-3 border w-100 "
                                                    aria-label="Recipient 's username" aria-describedby="basic-addon2">
                                                <div class="input-group-append position-absolute end-0">
                                                    <button (click)="copyText(tfa)" type="button"
                                                        class="btn fw-bold btn-theme">
                                                        {{copy}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 mb-3">
                                            <h6 class="fw-bold theme-gradient w-f ">Google Authentication Code <span
                                                    class="text-danger">*</span></h6>
                                            <div class="input-group ">
                                                <input maxlength="6" formControlName="otp" placeholder="Enter the code"
                                                    type="text" class="shadow p-2 rounded-3 border w-100"
                                                    [ngClass]="{'is-invalid': submitted && f['otp']?.errors}"
                                                    aria-label="Recipient's username" aria-describedby="basic-addon2 ">
                                                <div *ngIf="submitted && f['otp']?.errors" class="invalid-feedback">
                                                    <div *ngIf="f['otp']?.errors?.['required'] ">Google Authentication
                                                        Code
                                                        is Required</div>
                                                    <div *ngIf="f['otp']?.errors?.['pattern'] ">Invalid Code</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group text-end mt-20">
                                            <a routerLink="/settings" class="btn fw-bold btn-theme"><i
                                                    class="fa fa-long-arrow-left"></i> Back To
                                                Profile
                                            </a>
                                            <button (click)="submit() " type="button " name=" "
                                                class="btn fw-bold btn-theme ms-2">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>