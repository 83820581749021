<section>
    <div class="container-fluid px-sm-5 py-80">
        <div class="col-xxl-10 col-xl-10 mx-auto">
            <div
                class="row px-sm-5 px-2 justify-content-xxl-evenly justify-content-end align-items-center glass-card py-3">
                <div class="col-lg-3 order-lg-1 order-2 text-lg-start text-center">
                    <h3 class="text-white text-center mb-3">{{horseData?.name}}</h3>
                    <div class="row bg-dark p-3 rounded">
                        <div class="col-auto">
                            <img src="assets/img/diamond.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Generation</p>
                            <p class="text-white mb-0">First Generation</p>
                        </div>
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/blood.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Bloodline</p>
                            <p class="text-white mb-0">UCHIKA</p>
                        </div>
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/gender.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Gender</p>
                            <p class="text-white mb-0">{{horseData?.gender}}</p>
                        </div>
                    </div>
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/palette.png" width="40" alt="generation">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Coat</p>
                            <p class="text-white mb-0">Classic</p>
                        </div>
                    </div>
                </div>
                <div class="col-9 col order-lg-2 order-1 position-relative">
                    <div class="">
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="d-flex mb-2">
                                    <p class="level-bg mb-0">Lv {{horseData?.level}}</p>
                                    <p class="level-bg-1 mb-0 ms-2">{{horseData?.class}}</p>
                                    <!-- <p class="level-bg mb-0 ms-2">Borrowed</p> -->
                                </div>
        
                                <div class="col">
                                    <h6 class="text-white mb-2">XP {{horseData?.xp}}/{{horseData?.end_xp}}</h6>
                                    <div class="progress" role="progressbar" style="height: 8px;" aria-label="Info example"
                                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar bg-info" 
                                        [style]="'width:' + (horseData?.xp/horseData?.end_xp*100)+'%'"></div>
                                    </div>
                                </div>
                            </div>
                            <a style="cursor: pointer; text-decoration: none;" [href]="'https://sepolia.etherscan.io/tx/'+horseData?.transactionHash" target="_blank">
                                <img src="../../../assets/img/ethereum.png" width="30px" class="eth_image">
                                <span class="text-white ms-1">{{horseData?.transactionHash|slice:0:10}}...</span>
                            </a>
                        </div>
                    </div>
                    <div class="text-center">
                        <img [src]="horseData?.image_url"
                            class="img-fluid animate__animated animate__shakeY animate__infinite animate__slower"
                            width="500" alt="Horse">
                    </div>

                </div>
                <div class="col order-3">
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/flag.png" width="40" alt="race">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Races</p>
                            <p class="text-white mb-0">02</p>
                        </div>
                    </div>
                </div>
                <div class="col order-3 ms-3">
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/goal.png" width="40" alt="Coat">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Career</p>
                            <p class="text-white mb-0">9/4/5</p>
                        </div>
                    </div>
                </div>
                <div class="col order-3 ms-3">
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/trophy.png" width="40" alt="win">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">Win %</p>
                            <p class="text-white mb-0">22</p>
                        </div>
                    </div>
                </div>
                <div class="col order-3 ms-3">
                    <div class="row bg-dark p-3 rounded mt-3">
                        <div class="col-auto">
                            <img src="assets/img/dna.png" width="40" alt="DNA">
                        </div>
                        <div class="col">
                            <p class="text-secondary fw-medium mb-1">breeding decay</p>
                            <p class="text-white mb-0">Tred 0</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-3">
                <a routerLink="/dashboard" class="text-muted me-4 btn px-5 py-2 fw-bold btn-theme mt-4">Back</a>
                <a class="text-muted me-4 btn px-5 py-2 fw-bold btn-theme mt-4" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">Transfer</a>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-5 bg-theme">
            <div class="modal-header border-0">
                <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
                <button type="button" class="btn-close bg-white p-1" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center py-0">
                <h4 class="text-white">Transfer Racehorse</h4>
                <div class="myt-5 mb-3 text-start">
                    <label for="formGroupExampleInput" class="form-label text-theme fs-5">Wallet Address</label>
                    <input type="text" class="form-control" id="formGroupExampleInput" value="000xr00en22345mdx0ADSDFDF"
                        placeholder="">
                </div>
            </div>
            <div class="modal-footer border-0 justify-content-center pt-0 pb-4">
                <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2"
                    class="btn btn-theme w-100">Transfer</a>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModal2Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-5 bg-theme">
            <div class="modal-header border-0">
                <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
                <button type="button" class="btn-close bg-white p-1" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center py-0">
                <h4 class="text-white">Confirmation</h4>
                <div class="myt-5 mb-3">
                    <p class="text-theme">You are about to send <span class="text-white fw-bold"> True Blue Stable
                            racehorse to 000xr00en22345mdx0ADSDFDF wallet.</span> </p>
                </div>
            </div>
            <div class="modal-footer border-0 justify-content-center pt-0 pb-4">
                <div class="text-center mt-3">
                    <a class="text-muted me-4 btn px-5 py-2 fw-bold btn-light mt-4" data-bs-dismiss="modal"
                        aria-label="Close">Cancel</a>
                    <a href="https://opensea.io/collection/zed-run-official"
                        class="text-muted me-4 btn px-5 py-2 fw-bold btn-theme mt-4">Confirm</a>
                </div>
            </div>
        </div>
    </div>
</div>