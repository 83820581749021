import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/api.services';

@Component({
  selector: 'app-tfaverification',
  templateUrl: './tfaverification.component.html',
  styleUrls: ['./tfaverification.component.css']
})
export class TfaverificationComponent implements OnInit {

  user_tfa: any
  otp_form: any
  isSubmited: boolean = false

  constructor(private fb: FormBuilder,private router: Router,private callApi: UserService) { }

  ngOnInit(): void {
    this.otpForm()
  }

  otpForm() {
    this.otp_form = this.fb.group({
      otp: ['', [Validators.required]]
    })
    this.callApi.tfaStatus().subscribe((res: any) => {
      this.user_tfa = res.data
      if (this.user_tfa?.authentic_status === '2') {
        this.emailAuthentication()
      }
    })
  }

  emailAuthentication() {
    this.callApi.emailAuthentication().subscribe((resp: any) => {
      this.callApi.showInfo(resp?.message)
    })
  }

  get formVerify() { return this.otp_form.controls }

  verifyOTP() {
    this.isSubmited = true
    if (this.otp_form.valid) {
      let payload = {
        status: "0",
        ...this.otp_form.value
      }
      this.callApi.checkTfa(payload).subscribe((res: any) => {
        if (res.success) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/dashboard'])
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }

  emailTfaVerify() {
    if (this.otp_form.valid) {
      let payload = {
        twofa_otp: this.otp_form.get('otp').value,
        status: "0"
      }

      this.callApi.otpEmailVerification(payload).subscribe((res: any) => {
        if (res.success) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/dashboard'])
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }
  logOut() {
    this.callApi.logOut().subscribe((res: any) => {
      if (res.success) {
        sessionStorage.clear();
        this.router.navigate(['/login'])
      }
      else {
        sessionStorage.clear();
        this.router.navigate(['/login'])
      }
    })
  }
}

