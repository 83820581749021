<section class="py-80 pb-5">
    <div class="container">
        <div class="glass-card p-4">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex align-items-center">
                        <img *ngIf="!data?.userDeatils?.profile_img" src="assets/img/user.png" class="me-3" width="80"
                            alt="">
                        <img *ngIf="data?.userDeatils?.profile_img" [src]="imagePath+data?.userDeatils?.profile_img"
                            class="me-3" width="80" alt="">
                        <div>
                            <p class="text-theme mb-2">MY STABLE</p>
                            <!-- <h5 class="fw-bold text-white mb-0 fs-4">stable 1234554 <a href=""><img src="" alt=""></a>
                            </h5> -->
                            <p class="mb-0 fs-12">
                                <a class="user_kyc_a" routerLink="/kyc" *ngIf="data?.userDeatils?.kyc_verify == 0">
                                    KYC Status (Not Yet Submitted)
                                </a>
                                <a class="user_kyc_a" routerLink="/kyc" *ngIf="data?.userDeatils?.kyc_verify == 1">
                                    KYC Status (Waiting for Approval)
                                </a>
                                <a class="user_kyc_a" routerLink="/kyc" *ngIf="data?.userDeatils?.kyc_verify == 2">
                                    KYC Status (Verified)
                                </a>
                                <a class="user_kyc_a" routerLink="/kyc" *ngIf="data?.userDeatils?.kyc_verify == 3">
                                    KYC Status (Rejected)
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <a class="text-theme text-decoration-none" routerLink="/settings"><img src="assets/img/settings.png"
                            class="me-2" alt="">Settings</a>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <h6 class="mb-2 text-theme">THOROUGHBREDS</h6>
                    <p class="fw-bold text-white mb-0 fs-4">2</p>
                </div>
                <div class="col">
                    <h6 class="mb-2 text-theme">TOTAL CAREER</h6>
                    <p class="fw-bold text-white mb-0 fs-4">-/-/-</p>
                </div>
                <div class="col">
                    <h6 class="mb-2 text-theme">WIN RATE</h6>
                    <p class="fw-bold text-white mb-0 fs-4">--%</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- my stable LIST -->
<section class="pb-5">
    <div class="container">
        <div class="d-flex justify-content-between">
            <h2 class="text-theme mb-0">My stable</h2>
            <a routerLink="/choose-horse" class="btn px-5 py-2 fw-bold btn-theme">Own a racehorse</a>
        </div>
        <hr class="border-white">
        <div class="glass-card p-4 mt-4">
            <ngx-spinner type="ball-beat" size="medium"></ngx-spinner>
            <div class="row align-items-center" *ngFor="let data of ownedHorses">
                <div class="col-md-6">
                    <div class="d-flex">
                        <img [src]="data?.image_url" class="me-4 border bg-dark border-secondary" width="128"
                            alt="">
                        <div class="w-100">
                            <h5 class="fw-bold text-white mb-0 fs-4">{{data?.name}}<a href=""><img src="" alt=""></a>
                            </h5>
                            <ul class="text-theme list-unstyled mt-2">
                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                <li> Gender : <span class="text-white"> {{data?.gender}}</span></li>
                                <li> Coat : <span class="text-white"> Classic</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 text-end">
                    <a (click)="selectHorse(data)" class="btn px-5 py-2 fw-bold btn-theme">View Detail</a>
                </div>
                <hr class="mt-2">
            </div>
            <div *ngIf="this.ownedHorses == null" class="text-white text-center">
                NFT IS EMPTY!
            </div>
        </div>
    </div>
</section>