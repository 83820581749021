export default {
    "name": "arrowGlacier",
    "comment": "HF to delay the difficulty bomb",
    "url": "https://github.com/ethereum/execution-specs/blob/master/network-upgrades/mainnet-upgrades/arrow-glacier.md",
    "status": "Final",
    "eips": [4345],
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {}
};
