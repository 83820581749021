export const environment = {
    base_url : 'https://office.breedcoins.com/base/',
    socket_url:'wss://office.breedcoins.com',
    IMAGE_URL : "https://firebee.sgp1.digitaloceanspaces.com/",
    encrypt_decrypt_key:'walletex@123488&&*',
    PAGE_SIZE :5,

    // base_url : 'http://192.168.0.67:3000/',
    // socket_url:'ws://192.168.0.67:3000/',
    // IMAGE_URL : "https://firebee.sgp1.digitaloceanspaces.com/",
    // encrypt_decrypt_key:'walletex@123488&&*',
    // PAGE_SIZE :5,
};
