<section class="py-80 pt-0">
    <div class="container">
        <h3 class="text-center mb-3 fs-1 fw-bold theme-gradient w-f mx-auto">CHOOSE A RACE HORSE</h3>
        <!-- Swiper -->
        <div thumbsSlider="" class="swiper mySwiper bg-dark rounded-4">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let data of horseDetails">
                    <img [src]="data?.image_url">
                </div>
            </div>
        </div>
        <div class="swiper mySwiper2 bg-transparent mt-n1" #swiperContainer>
            <div class="swiper-wrapper">
                <div class="swiper-slide w-100" *ngFor="let data of horseDetails">
                    <div class="col-xxl-6 col-xl-5 col-sm-6 col-8">
                        <div class="shadow-lg rounded-4 glass-card p-3">
                            <img [src]="data?.image_url"
                                class="animate__animated animate__shakeY animate__infinite animate__slower" alt="">
                            <h3 class="my-3 text-white w-f mx-auto">{{data?.name}}</h3>
                            <a (click)="selectHorse(data)" class="btn btn-theme px-4 py-2 fw-bold">Select</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div>
        <p class="text-center mt-4 text-white fs-5">or,
            <a href="" class="theme-gradient fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal">
                already own a racehorse?
            </a>
        </p>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-5 bg-theme">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close bg-white p-1" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body text-center py-0">
                    <h4 class="text-white">ALREADY OWN A RACEHORSE</h4>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="modal-footer border-0 justify-content-center pt-0 pb-4">
                    <div class="col-5">
                        <a routerLink="/dashboard" data-bs-dismiss="modal" type="button"
                            class="btn btn-secondary w-100">
                            Skip
                        </a>
                    </div>
                    <div class="col-5">
                        <button type="button" class="btn btn-theme w-100" data-bs-dismiss="modal">Go back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>