import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/api.services';
import { environment } from '../../../environments/environment.development';
import { Router } from '@angular/router';
import { Web3Service } from '../../services/web3.service';
import { ethers } from 'ethers';
declare const window: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  data: any;
  imagePath = environment.IMAGE_URL
  ownedHorses: any = [];
  provider!: ethers.providers.Web3Provider
  account!: string;

  constructor(private callApi: UserService, private router: Router, private web3service: Web3Service) {
    this.changesDetect()
  }

  ngOnInit(): void {
    this.getCommonData();
  }

  async getCommonData() {
    this.callApi.getProfile().subscribe( async (res: any) => {
      if (await res?.success) {
        this.data = res?.data
        let encryptProfileImg = this.callApi.encryptData(this.data?.userDeatils?.profile_img)
        sessionStorage.setItem('profile-img', encryptProfileImg)
        this.callApi.publishEvent({ eventName: 'Login' })
        await this.web3service.openMetamask().then(async (res) => {
          if (await res) {
            this.getOurHorse(res)
          } else {
            this.callApi.showError('Kindly Check Metamask Connection!')
          }
        })
      }
    })
  }

  getOurHorse(address: String) {
    this.callApi.startSpin()
    this.callApi.userHorse({ wallet_address: address }).subscribe(async (res: any) => {
      if (await res?.success) {
        this.ownedHorses = res?.data
        this.callApi.stopSpin()
      } else {
        this.callApi.stopSpin()
        this.ownedHorses = res?.data
      }
    })
  }

  selectHorse(data: any) {
    this.router.navigate(['/view-detail'], { queryParams: { id: data?._id } })
  }

  async changesDetect() {
    if (typeof window.ethereum !== 'undefined') {
      this.provider = new ethers.providers.Web3Provider(window.ethereum);
      // Request account access
      let request = await this.provider.send('eth_requestAccounts', []);
      // Listen for account changes
      this.provider.on('accountsChanged', (accounts) => {
        this.account = accounts[0];
        // Update your application state here
      });
    }
  }
}
