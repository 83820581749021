<section class="py-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12 mx-md-auto text-md-center">
                <div class="">
                    <img src="../../../assets/img/otp.gif" alt=""
                        class=" img-fluid animate__zoomIn wow img-fluid  animate__animated animate__slower">
                </div>
            </div>
            <div class="col-6">
                <div class="glass-card">
                    <div class="mt-3">
                        <h2 class="fw-bold theme-gradient mb-4 w-f mx-auto">Enter E-mail OTP</h2>
                        <form [formGroup]="form">
                            <div class="pt-5 ps-5 pe-5 pb-3">
                                <label for="exampleInputEmail1" class="fw-bold theme-gradient mb-4 w-f">OTP <span
                                        class="text-danger">*</span></label>
                                <input formControlName="otp" placeholder="Enter the code" maxlength="6"
                                    type="text" class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                                    [ngClass]="{'is-invalid' : submitted && f['otp'].errors}"
                                    aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div *ngIf="submitted && f['otp'].errors" class="invalid-feedback">
                                    <div *ngIf="f['otp'].errors?.['required']">Email OTP is Required</div>
                                    <div *ngIf="f['otp'].errors?.['pattern']">Invalid Code</div>
                                </div>
                            </div>
                            <div class="text-center mt-3 mb-4">
                                <a type="submit" (click)="submit()" class="btn fw-bold btn-theme">Submit</a>
                            </div>
                            <div class="btn_div text-center mt-3 mb-4">
                                <button class="btn fw-bold btn-theme" (click)="resendOTP()">
                                    <i class="loading-icon fa me-2"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                                    {{button}}
                                </button>
                            </div>
                            <div class="btn_div text-center mt-3 mb-4">
                                <button routerLink="/profile" class="btn fw-bold btn-theme"><i
                                        class="fa fa-long-arrow-left"></i>
                                    Back To Profile
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>