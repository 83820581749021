import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/api.services';

@Component({
  selector: 'app-emailotp',
  templateUrl: './emailotp.component.html',
  styleUrls: ['./emailotp.component.scss']
})
export class EmailotpComponent implements OnInit {

  form: any;
  submitted: boolean = false;
  button = 'Resend Code'
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, private callApi: UserService,
    private router: Router) { }


  ngOnInit(): void {
    this.createForm();
  }

  get f() { return this.form.controls; }

  createForm() {
    this.form = this.fb.group({
      otp: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      status: ["1"]
    })
  }

  submit() {
    this.submitted = true
    if (this.form.valid) {
      this.callApi.otpEmailVerification(this.form.value).subscribe((res: any) => {
        if (res.success == true) {
          this.router.navigate(['/settings'])
          this.callApi.showSuccess(res.message)
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }

  resendOTP() {
    this.button = 'Processing'
    this.isLoading = true
    this.callApi.tfaresendmailotp({}).subscribe((res: any) => {
      if (res.success == true) {
        this.callApi.showSuccess(res.message)
        this.button = 'Resend Code'
        this.isLoading = false
      } else {
        this.callApi.showError(res.message)
        this.button = 'Resend Code'
        this.isLoading = false
      }
    }, err => {
      this.button = 'Resend Code'
      this.isLoading = false
    })
  }

}
