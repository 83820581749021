import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/api.services';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginAuthGuard implements CanActivate {

  constructor(private callApi: UserService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let token = this.callApi.getToken()
    if (token) {
      return true
    } else {
      this.router.navigateByUrl('/login')
      sessionStorage.clear()
      return false
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginAuthGuard implements CanActivate {

  constructor(private callApi: UserService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let token = this.callApi.getToken()
    if (!token) {
      return true
    } else {
      this.router.navigateByUrl('/dashboard')
      return false
    }
  }

}