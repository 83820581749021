import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/api.services';
@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrl: './verify-otp.component.css'
})
export class VerifyOtpComponent implements OnInit {

  showOtp: any;
  form: any;
  submitted: boolean = false;
  email: any
  button = 'Resend Code'
  isLoading: boolean = false

  constructor(private callApi: UserService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res: any) => {
      const decrypt_email = this.callApi.decryptData(res?.mail)
      this.email = decrypt_email
    })
    this.createForm();
    this.showOtp = sessionStorage.getItem('otp')
  }

  get f() { return this.form.controls; }

  createForm() {
    this.form = this.fb.group({
      otp: [null, [Validators.required, Validators.pattern(/^((\\+91-?)|0)?[0-9]{6}$/)]]
    })
  }

  submit() {
    this.submitted = true
    let formvalid = this.form.value;
    if (!this.form.invalid) {
      let payload = {
        email: this.email,
        otp: formvalid.otp
      }
      this.callApi.verifyOtp(payload).subscribe((res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/login'])
          this.showOtp = res?.data?.otp
        }
        else {
          this.callApi.showError(res.message)
        }
      })
    }
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  back() {
    sessionStorage.clear();
    this.router.navigate(['/register'])
  }

  resendOTP() {
    this.button = 'Processing',
    this.isLoading = true
    this.callApi.resendmailotp({ email: this.email }).subscribe((res: any) => {
      if (res.success == true) {
        this.callApi.showSuccess(res.message)
        this.button = 'Resend Code',
        this.isLoading = false
      }
      else {
        this.callApi.showError(res.message)
        this.button = 'Resend Code',
          this.isLoading = false
      }
    }, err => {
      this.button = 'Resend Code',
      this.isLoading = false
    })
  }

}
