import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/api.services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  form : any;
  button : string = 'Login';
  isLoading : boolean = false;
  submitted : boolean = false;
  public showPassword: boolean = false;

  constructor(private fb: FormBuilder,private deviceService: DeviceDetectorService,
              private callApi: UserService,private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  get f() { return this.form.controls; }

  createForm() {
    //3rd Party
    let emailregex: RegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.minLength(3)]],
      password: [null, Validators.required]
    })
  }

  get device(): any {
    return this.deviceService.getDeviceInfo();
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 46 || charCode > 57 && charCode <= 63 || charCode > 64 && charCode < 65 || charCode > 90 && charCode < 95 || charCode > 122 || charCode == 47) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  submit() {    
    this.button = 'Processing';
    this.isLoading = true;
    this.submitted = true
    if (this.form.valid) {
      this.submitted = false
      this.callApi.login(this.form.value).subscribe(async (res: any) => {

        let user_details: any = res?.data
        // check email verification
        if (user_details?.email_verify == 0) {
          const mail = this.callApi.encryptData(user_details?.email)
          this.callApi.showInfo(res.message)
          this.router.navigate(['/otp-verification'], { queryParams: { mail: mail } })
          return
        }

        //verified user
        if (res?.success) {
          sessionStorage.setItem('zed_token', user_details?.token)
          this.callApi.getToken()
          // check two factor authentication
          if (user_details?.userdata?.authentic_status != "0") {
            this.router.navigate(['/tfa_verification'])
          } else {
            this.callApi.showSuccess(res.message)
            this.router.navigate(['/dashboard'])
          }
        } else {
          this.callApi.showError(res.message)
          this.button = 'Login';
          this.isLoading = false;
        }
      }, err => {
        this.button = 'Login';
        this.isLoading = false;
        this.callApi.showError('Try again later')
      })
    } else {
      this.button = 'Login';
      this.isLoading = false;
    }
  }
}
