<section class="py-80">
    <div class="container">
        <div>
            <a href="/breed.html" class="text-theme text-decoration-none"> <span class="fs-5 fw-bold me-3">
                    &#8672;</span> Back</a>
            <h1 class="text-center text-secondary mt-n3">Breeding</h1>
        </div>
        <div class="row mt-5 align-items-center">
            <div class="col-5">
                <button class="text-center bg-transparent border-0 w-100" data-bs-toggle="modal"
                    data-bs-target="#femalehorse">
                    <h5 class="text-theme">Female</h5>
                    <div class="border border-secondary rounded-4 p-3 text-theme">
                        <h3>Bella</h3>
                        <p>You have select <span class="text-light">Bella</span> , she is a beautiful</p>
                    </div>
                </button>
            </div>
            <div class="col text-center">
                <img src="assets/img/love.png" class="animate__animated animate__pulse animate__infinite" width="40"
                    alt="love">
            </div>
            <div class="col-5">
                <div class="text-center">
                    <h5 class="text-theme">stallion</h5>
                    <div class="border border-secondary rounded-4 p-3 text-theme">
                        <h3>True blue stable</h3>
                        <p>You have select <span class="text-light">true blue stable</span> , he is ready to mate</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid px-5">
        <div class="row mt-5">
            <div class="col">
                <div class="row">
                    <div class="col-xxl-4 col-10 mx-auto order-xxl-1 order-2">
                        <div class="row bg-dark p-3 rounded">
                            <div class="col-auto">
                                <img src="assets/img/diamond.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Generation</p>
                                <p class="text-white mb-0">First Generation</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/blood.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Bloodline</p>
                                <p class="text-white mb-0">UCHIKA</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/gender.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Gender</p>
                                <p class="text-white mb-0">stallion</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/palette.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Coat</p>
                                <p class="text-white mb-0">Classic</p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-xxl-8  order-xxl-2 order-1 animate__animated animate__fadeIn animate__slow animate__delay-1s">
                        <img src="assets/img/horse4.webp" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-xxl-8  order-xxl-2 order-1">
                        <img src="assets/img/horse1.webp" class="img-fluid stud" alt="">
                        <ol></ol>
                    </div>
                    <div class="col-xxl-4 col-10 mx-auto order-2">
                        <div class="row bg-dark p-3 rounded">
                            <div class="col-auto">
                                <img src="assets/img/diamond.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Generation</p>
                                <p class="text-white mb-0">First Generation</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/blood.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Bloodline</p>
                                <p class="text-white mb-0">UCHIKA</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/gender.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Gender</p>
                                <p class="text-white mb-0">stallion</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/palette.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Coat</p>
                                <p class="text-white mb-0">Classic</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="bg-dark">
        <div class="row">
            <div class="glass-card p-4 text-center">
                <p class="text-theme fw-semibold">Start Breeding by selecting 'Buy Cover', <span class="text-white">
                        true blue stable</span> and <span class="text-white">Bella</span> will create your very own
                    offspring. Once the birthing process is complete,you will be able to give your offspring its very
                    own unique name from your stable.</p>
                <div class="text-center">
                    <span class="text-theme fw-bold">Fees : <span class="text-white fw-bold"> 0.075 ETH</span></span>
                    <a class="btn btn-theme ms-5" href="/dashboard.html" data-bs-toggle="modal"
                        data-bs-target="#buy_cover"> Buy cover </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="buy_cover" tabindex="-1" aria-labelledby="buy_coverLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-5 bg-theme">
            <div class="modal-header border-0">
                <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
                <button type="button" class="btn-close bg-white p-1" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body py-0 px-4">
                <h4 class="text-white text-center">Transction Confirmation</h4>
                <div class="my-3 text-theme fw-bold">
                    <p class="mb-2"> STUD FEE</p>
                    <p> <span class="text-success">$10.06</span> USD</p>
                </div>
                <div class="my-3 text-theme fw-bold">
                    <p class="mb-2"> ESTIMATED TX FEE</p>
                    <p> <span class="text-success">$0</span> USD</p>
                </div>
                <div class="my-3 text-theme fw-bold">
                    <p class="mb-2"> ESTIMATED TOTAL</p>
                    <p> <span class="text-success">$10.06</span> USD</p>
                </div>
                <hr>
            </div>
            <div class="modal-footer border-0 justify-content-center pt-0 pb-4">
                <div class="text-center mt-3">
                    <a class="text-muted me-4 btn px-5 py-2 fw-bold btn-light" data-bs-dismiss="modal"
                        aria-label="Close">Cancel</a>
                    <a class="text-muted me-4 btn px-5 py-2 fw-bold btn-theme" data-bs-toggle="modal"
                        data-bs-target="#buy_cover2">Confirm</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="buy_cover2" tabindex="-1" aria-labelledby="buy_cover2Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-5 bg-theme">
            <div class="modal-header border-0">
                <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
                <button type="button" class="btn-close bg-white p-1" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body py-0 px-4">
                <h4 class="text-white text-center">Procreating</h4>
                <p class="text-theme">Please note it may take upto 5 minutes for your newborn to appear in your stable.
                </p>
                <hr>
            </div>
            <div class="modal-footer border-0 justify-content-center pt-0 pb-4">
                <div class="text-center mt-3">
                    <a routerLink="/breed" data-bs-dismiss="modal" class="text-muted me-4 btn px-5 py-2 fw-bold btn-light">back to stud</a>
                    <a routerLink="/dashboard" data-bs-dismiss="modal" class="text-muted me-4 btn px-5 py-2 fw-bold btn-theme">Go to
                        stable</a>
                </div>
            </div>
        </div>
    </div>
</div>