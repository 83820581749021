<section class="py-80">
    <div class="container-fluid ps-md-0">
        <div class="row text-md-start text-center align-items-center">
            <div class="col-6 d-md-block d-none">
                <img src="assets/img/horse_right.png" class="img-fluid" alt="Horse">
            </div>
            <div class="col-lg-4 col-md-5 col-sm-10 col-11 mx-auto">
                <form [formGroup]="otp_form" autocomplete="off" class="glass-card p-sm-4 p-2 text-center">
                    <h1 *ngIf="user_tfa?.authentic_status === '1'" class="fw-bold theme-gradient mb-4 w-f mx-auto">
                        Google Authenticate Code</h1>
                    <h1 *ngIf="user_tfa?.authentic_status === '2'" class="fw-bold theme-gradient mb-4 w-f mx-auto">Email
                        Authenticate Code</h1>
                    <div class="mb-4 ">
                        <input class="form-control bg-dark border-color py-3 ph-color shadow text-white"
                            placeholder="Enter 2FA code.." formControlName="otp" maxlength="6" type="text">
                        <div *ngIf="isSubmited" class="text-danger">
                            <div *ngIf="formVerify['otp'].errors?.['required']"> OTP is required</div>
                            <div
                                *ngIf="formVerify['otp'].errors?.['minlength']||formVerify['otp'].errors?.['maxlength']">
                                OTP Must be 6 Digits</div>
                        </div>
                    </div>
                    <a *ngIf="user_tfa?.authentic_status === '1'" type="submit" (click)="verifyOTP()"
                        class="btn px-5 py-3 fw-bold btn-theme w-100">
                        Verify
                    </a>
                    <a *ngIf="user_tfa?.authentic_status === '2'" type="submit" (click)="emailTfaVerify()"
                        class="btn px-5 py-3 fw-bold btn-theme w-100">
                        Verify
                    </a>
                    <a *ngIf="user_tfa?.authentic_status === '2'" type="submit" (click)="emailAuthentication()"
                        class="btn fw-bold btn-theme ms-2">
                        Resend OTP
                    </a>
                </form>
                <div class="mt-3 action-btns">
                    <a class="btn fw-bold btn-theme ms-2" routerLink="/tfa-losting">2FA Code Losting?</a>
                    <a class="btn fw-bold btn-theme ms-2" style="cursor: pointer;" (click)="logOut()">Back?</a>
                </div>
            </div>
        </div>
    </div>
</section>