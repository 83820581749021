import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/api.services';
import { PasswordStrengthValidator } from '../../services/password-strength.validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form: any;
  submitted: boolean = false
  checkingPassword: boolean = false;
  public showPassword: boolean = false;
  public confirmPassword: boolean = false;
  i: any;
  confirmPass: boolean = false;
  token: any;
  button = 'Register';
  isLoading = false;

  constructor(private fb: FormBuilder,private callApi: UserService,private router: Router) { }

  ngOnInit(): void {
    this.createForm()
  }

  get f(): any { return this.form.controls; }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.form = this.fb.group({
      user_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      email: [null, [Validators.required, Validators.pattern(emailregex)]],
      password: [null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: this.MustMatch('password', 'confirmPassword')
      })
  }

  submit() {
    this.button = 'Processing';
    this.isLoading = true;
    this.submitted = true
    if (!this.form.invalid) {
      let payload = {
        ...this.form.value,
      }
      this.callApi.register(payload).subscribe((res: any) => {
        this.button = 'Register';
        this.isLoading = false;
        if (res.success == true) {
          this.callApi.showSuccess(res.message)
          const encrypted_mail = this.callApi.encryptData(res?.data?.email)
          this.router.navigate(['/otp-verification'], { queryParams: { mail: encrypted_mail } })
        } else {
          this.button = 'Register';
          this.isLoading = false;
          this.callApi.showError(res.message)
        }
      })
    } else {
      this.button = 'Register';
      this.isLoading = false;
    }
  }

  userNameValidation(event: any) {
    if (!event.key.match(/^[a-zA-Z0-9._]+$/)) {
      event.preventDefault();
    }
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode < 46 || charCode > 57 && charCode <= 63 || charCode > 64 && charCode < 65 || charCode > 90 && charCode < 95 || charCode > 122 || charCode == 47) {
      event.preventDefault();
      return false
    }
    else {
      return true;
    }
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
