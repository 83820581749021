<section class="py-80">
    <div class="container">
        <div>
            <a href="/breed.html" class="text-theme text-decoration-none"> <span class="fs-5 fw-bold me-3">
                    &#8672;</span> Back</a>
            <h1 class="text-center text-secondary mt-n3">Breeding</h1>
        </div>
        <div class="row mt-5 align-items-center">
            <div class="col-md-5">
                <button class="text-center bg-transparent border-0 w-100" data-bs-toggle="modal"
                    data-bs-target="#femalehorse">
                    <h5 class="text-theme">Female</h5>
                    <div class="border border-secondary rounded-4 p-3 text-theme">
                        <h3>-/-/-</h3>
                        <p class="mb-0">Select your female horse</p>
                    </div>
                </button>
            </div>
            <div class="col text-center">
                <img src="assets/img/love.png" class="animate__animated animate__pulse animate__infinite" width="40"
                    alt="love">
            </div>
            <div class="col-md-5">
                <div class="text-center">
                    <h5 class="text-theme">stallion</h5>
                    <div class="border border-secondary rounded-4 p-3 text-theme">
                        <h3>True blue stable</h3>
                        <p>You have select <span class="text-light">true blue stable</span> , he is ready to mate</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid px-5">
        <div class="row mt-5">
            <div class="col">
                <div class="row">
                    <div class="col-xxl-4 col-10 mx-auto order-xxl-1 order-2">
                        <div class="row bg-dark p-3 rounded">
                            <div class="col-auto">
                                <img src="assets/img/diamond.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Generation</p>
                                <p class="text-white mb-0">-/-/-</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/blood.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Bloodline</p>
                                <p class="text-white mb-0">-/-/-</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/gender.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Gender</p>
                                <p class="text-white mb-0">-/-/-</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/palette.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Coat</p>
                                <p class="text-white mb-0">-/-/-</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-8  order-xxl-2 order-1">
                        <img src="assets/img/horseshade.webp" class="img-fluid" alt="">
                        <ol></ol>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-xxl-8  order-xxl-2 order-1">
                        <img src="assets/img/horse1.webp" class="img-fluid" alt="">
                        <ol></ol>
                    </div>
                    <div class="col-xxl-4 col-10 mx-auto order-2">
                        <div class="row bg-dark p-3 rounded">
                            <div class="col-auto">
                                <img src="assets/img/diamond.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Generation</p>
                                <p class="text-white mb-0">First Generation</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/blood.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Bloodline</p>
                                <p class="text-white mb-0">UCHIKA</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/gender.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Gender</p>
                                <p class="text-white mb-0">stallion</p>
                            </div>
                        </div>
                        <div class="row bg-dark p-3 rounded mt-3">
                            <div class="col-auto">
                                <img src="assets/img/palette.png" width="40" alt="generation">
                            </div>
                            <div class="col">
                                <p class="text-secondary fw-medium mb-1">Coat</p>
                                <p class="text-white mb-0">Classic</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="femalehorse" tabindex="-1" aria-labelledby="femalehorselabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content  bg-dark">
            <div class="modal-header border-0">
                <h1 class="modal-title fs-5 text-white" id="exampleModalLabel"></h1>
                <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- my stable LIST -->
                <section class="">
                    <div class="container">
                        <h2 class="text-theme mb-0">Select Female Horse</h2>
                        <div class="row mt-3">
                            <div class="col-3">
                                <input type="text" class="form-control bg-transparent text-theme" placeholder=""
                                    value="Search" aria-label="First name">
                            </div>
                            <div class="col">
                                <div class="dropdown">
                                    <button class="btn btn-secondary bg-transparent dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Filter
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    <span class="ms-3 text-success">Clear all</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <select class="form-select bg-transparent text-theme"
                                    aria-label="Default select example">
                                    <option selected>Sort By</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <hr class="border-white">
                        <div class="glass-card p-4 mt-4">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex">
                                        <img src="assets/img/horse_1.webp" class="me-4 border bg-dark border-secondary"
                                            width="128" alt="">
                                        <div class="w-100">
                                            <h5 class="fw-bold text-white mb-0 fs-4">Guinevere<a href=""><img src=""
                                                        alt=""></a></h5>
                                            <ul class="text-theme list-unstyled mt-2">
                                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                                <li> Gender : <span class="text-white"> mare</span></li>
                                                <li> Coat : <span class="text-white"> Classic</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-end">
                                    <a routerLink="/start-breed" data-bs-dismiss="modal" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                                </div>
                            </div>
                        </div>

                        <div class="glass-card p-4 mt-4">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex">
                                        <img src="assets/img/horse_4.webp" class="me-4 border bg-dark border-secondary"
                                            width="128" alt="">
                                        <div class="w-100">
                                            <h5 class="fw-bold text-white mb-0 fs-4">Adira<a href=""><img src=""
                                                        alt=""></a></h5>
                                            <ul class="text-theme list-unstyled mt-2">
                                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                                <li> Gender : <span class="text-white"> mare</span></li>
                                                <li> Coat : <span class="text-white"> Classic</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-end">
                                    <a routerLink="/start-breed" data-bs-dismiss="modal" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                                </div>
                            </div>
                        </div>
                        <div class="glass-card p-4 mt-4">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex">
                                        <img src="assets/img/horse_2.webp" class="me-4 border bg-dark border-secondary"
                                            width="128" alt="">
                                        <div class="w-100">
                                            <h5 class="fw-bold text-white mb-0 fs-4">Dakota<a href=""><img src=""
                                                        alt=""></a></h5>
                                            <ul class="text-theme list-unstyled mt-2">
                                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                                <li> Gender : <span class="text-white"> mare</span></li>
                                                <li> Coat : <span class="text-white"> Classic</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-end">
                                    <a routerLink="/start-breed" data-bs-dismiss="modal" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                                </div>
                            </div>
                        </div>
                        <div class="glass-card p-4 mt-4">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex">
                                        <img src="assets/img/horse_3.webp" class="me-4 border bg-dark border-secondary"
                                            width="128" alt="">
                                        <div class="w-100">
                                            <h5 class="fw-bold text-white mb-0 fs-4">Lady Fox<a href=""><img src=""
                                                        alt=""></a></h5>
                                            <ul class="text-theme list-unstyled mt-2">
                                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                                <li> Gender : <span class="text-white"> mare</span></li>
                                                <li> Coat : <span class="text-white"> Classic</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-end">
                                    <a routerLink="/start-breed" data-bs-dismiss="modal" class="btn px-5 py-2 fw-bold btn-theme">Select mate</a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 text-center">
                            <a href="" class="btn px-5 py-2 fw-bold btn-theme">load more</a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>