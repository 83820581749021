import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/api.services';

@Component({
  selector: 'app-usertwofa',
  templateUrl: './usertwofa.component.html',
  styleUrls: ['./usertwofa.component.scss']
})
export class UsertwofaComponent implements OnInit {

  form: any;
  submitted: boolean = false;
  tfaQr: any;
  tfa: any;
  copy: any = 'Copy'

  constructor(private callApi: UserService, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copy = 'Copied!'

    setTimeout(() => {
      this.copy = 'Copy'
    }, 2000);
  }

  get f() { return this.form.controls }

  createForm() {
    this.form = this.fb.group({
      otp: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      secrectkey:[],
      status: ["1"]
    })
    this.getTfa();
  }

  submit() {
    this.submitted = true
    if (this.form.valid) {
      this.callApi.checkTfa(this.form.value).subscribe((res: any) => {
        if (res.success === true) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/settings'])
        } else {
          this.callApi.showError(res.message)
        }
      })
    }
  }

  getTfa() {
    this.callApi.getTfa().subscribe((res: any) => {      
      this.tfaQr = res?.data
      this.tfa = res?.data?.secrectkey
      this.form.patchValue({ "secrectkey": this.tfa });
    })
  }

}