export default {
    "name": "grayGlacier",
    "comment": "Delaying the difficulty bomb to Mid September 2022",
    "url": "https://github.com/ethereum/execution-specs/blob/master/network-upgrades/mainnet-upgrades/gray-glacier.md",
    "status": "Draft",
    "eips": [5133],
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {}
};
