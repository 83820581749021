import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { UserService } from '../../services/api.services';
import { ImageValidationService } from '../../services/image-validation.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-kycuser',
  templateUrl: './kycuser.component.html',
  styleUrls: ['./kycuser.component.scss']
})
export class KycuserComponent implements OnInit {

  base_url: any = environment.IMAGE_URL;
  isEditable: any = true;
  acInfoForm: FormGroup | any;
  idCredentialForm: FormGroup | any;
  uploadDocForm: FormGroup | any;
  country: any;
  idTypeList: any;
  image: any = {}
  dialCodeData: any;
  id_type: boolean = false;
  minDate: any = new Date();
  maxDate: any = this.minDate.getY;
  data: any;
  submitted: boolean = false
  countryName: any;
  kyc_verify: any;
  button: string = 'Submit'
  isLoading: boolean = false;
  today: any;
  selfieImg: any;
  constructor(private callApi: UserService, private datePipe: DatePipe, private router: Router,
    private fb: FormBuilder, private validimage: ImageValidationService) {
  }

  ngOnInit(): void {
    this.getProfileDetails()
    this.today = new Date();
    this.maxDate = new Date(this.today.getTime() - (157680.24 * 60 * 60 * 1000)).toISOString().split('T')[0];
    this.minDate = new Date(this.today.getFullYear() - (157680.24 * 145 * 60 * 1000)).toISOString().split('T')[0]
    this.acInfoForm = this.fb.group({
      first_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      last_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      date: [null, [Validators.required]],
      city: [null, [Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.minLength(3), Validators.maxLength(60)]],
      country: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
    })

    this.idCredentialForm = this.fb.group({
      id_type: [null, [Validators.required]],
      id_number: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
      address: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      expiry_date: [null],
    })

    this.uploadDocForm = this.fb.group({
      id_front_image: [null, [Validators.required]],
      id_back_image: [null, [Validators.required]],
      selfie_holding: [null]
    })

    this.callApi.getAllCountries().subscribe((res: any) => {
      this.country = res?.data;
    })
    this.getKycParams()
  }
  getProfileDetails() {
    this.callApi.getProfile().subscribe((res: any) => {
      this.kyc_verify = res?.data?.userDeatils?.kyc_verify
      this.data = res?.data?.kycDetails
      if (this.kyc_verify != 3) {
        this.dialCodeData = this.data?.country_code
        this.acInfoForm.patchValue(this.data)
        this.idCredentialForm.patchValue(this.data)
        this.acInfoForm.patchValue({ date: this.datePipe.transform(this.data?.dob, ('yyyy-MM-dd')) })
        this.kyc_verify == 0 ? null : this.image.front = this.data?.id_front_image;
        this.kyc_verify == 0 ? null : this.image.back = this.data?.id_back_image;
        this.selfieImg = this.data?.selfie_holding
        let event: any = { target: { value: this.data?.country } }
        this.dialCode(event);
        let idevent: any = { target: { value: this.data?.id_type } }
        this.idchange(idevent)
      }
    })
  }
  get f() { return this.uploadDocForm.controls; }

  getKycParams() {
    this.callApi.getKycParams().subscribe((res: any) => {
      this.idTypeList = res.data
      this.idTypeList.forEach((element: any, index: any) => {
        if (this.countryName != 'India' && element.name == 'Aadhar Card') {
          this.idTypeList.splice(index, 1);
        }
      });
    })
  }

  idchange(event: any) {
    if (event?.target?.value == 'National ID' || event?.target?.value == 'Aadhar Card' || event == 'National ID' || event == 'Aadhar Card') {
      this.id_type = true
      this.idCredentialForm.controls["expiry_date"].clearValidators()
      this.idCredentialForm.controls["expiry_date"].updateValueAndValidity()
    } else {
      this.idCredentialForm.controls["expiry_date"].setValidators(Validators.required)
      this.idCredentialForm.controls["expiry_date"].updateValueAndValidity()
      this.id_type = false
    }
  }

  uploadImg(event: any, type: any) {
    let image = event.target.files[0];
    let front = new FormData();
    if (type == 1) {
      let data = this.validimage.fileUploadValidator(event)
      if (data == false) {
        this.callApi.showError('File Format Like JPG,PNG,JPEG')
      } else {
        front.append('id_front_image', image)
        this.callApi.uploadKycImage(front).subscribe((res: any) => {
          if (res.success == true) {
            this.uploadDocForm.patchValue({ id_front_image: res.data.id_front_image })
            this.image.front = res.data.id_front_image;
          } else {
            this.callApi.showError(res.message)
          }
        })
      }
    }
    else {
      let data = this.validimage.fileUploadValidator(event)
      if (data == false) {
        this.callApi.showError('File Format Like JPG,PNG,JPEG')
      } else {
        front.append('id_back_image', image)
        this.callApi.uploadKycImage(front).subscribe((res: any) => {
          if (res.success == true) {
            this.uploadDocForm.patchValue({ id_back_image: res.data.id_back_image });
            this.image.back = res.data.id_back_image;
          } else {
            this.callApi.showError(res.message)
          }
        })
      }
    }
  }

  submit() {
    this.submitted = true
    if (this.uploadDocForm.valid) {
      let form_data: FormData = new FormData()
      this.button = 'Processing'
      this.isLoading = true
      let payload: any = {
        ...this.acInfoForm.value,
        ...this.uploadDocForm.value,
        ...this.idCredentialForm.value,
        country_code: this.dialCodeData
      }
      payload.date = this.datePipe.transform(payload.date, 'yyyy-MM-dd');
      payload.expiry_date = this.datePipe.transform(payload.expiry_date, 'yyyy-MM-dd');
      form_data.append('first_name', payload?.first_name)
      form_data.append('country_code', payload?.country_code)
      form_data.append('last_name', payload?.last_name)
      form_data.append('date', payload?.date)
      form_data.append('city', payload?.city)
      form_data.append('country', payload?.country)
      form_data.append('phoneNumber', payload?.phoneNumber)
      form_data.append('id_type', payload?.id_type)
      form_data.append('id_number', payload?.id_number)
      form_data.append('address', payload?.address)
      form_data.append('expiry_date', payload?.expiry_date)
      form_data.append('selfie_holding', payload?.selfie_holding)
      form_data.append('id_front_image', payload?.id_front_image)
      form_data.append('id_back_image', payload?.id_back_image)
      this.callApi.submitKyc(form_data).subscribe((res: any) => {
        if (res.success) {
          this.router.navigate(['/settings'])
          this.callApi.showSuccess(res.message)
          this.submitted = false
          this.button = 'Submit'
          this.isLoading = false
        }
        else {
          this.button = 'Submit'
          this.isLoading = false
          this.callApi.showError(res.message)
        }
      })
    }
  }
  dialCode(event: any) {
    this.countryName = event
    this.country.map((item: any) => {
      if (item.name == event) {
        this.dialCodeData = item.dial_code
      }
    })
    this.getKycParams()
  }

  stringValidation(event: any) {
    if (!event.key.match(/^[a-zA-Z]+$/)) {
      event.preventDefault();
    }
  }

  numberValidation(event: any) {
    if (!event.key.match(/^[0-9]+$/)) {
      event.preventDefault();
    }
  }

  userNameValidation(event: any) {
    if (!event.key.match(/^[a-zA-Z0-9._]+$/)) {
      event.preventDefault();
    }
  }
  addressValidators(event: any) {
    if (!event.key.match(/^[A-Za-z-0-99999999 ]+$/)) {
      event.preventDefault();
    }
  }
  validation(event: any) {
    if (!event.key.match(`^[a-zA-Z]*$`)) {
      event.preventDefault()
    }
  }

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: any;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: any;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    // let blob = new Blob([this.webcamImage?._imageAsDataUrl], {type:this.webcamImage?._mimeType});
    let base64String = webcamImage!.imageAsDataUrl;

    // Remove the data URL prefix
    let base64Data = base64String.split(',')[1];

    // Convert base64 to raw binary data held in a string
    let byteString = window.atob(base64Data);
    // Separate into a byte array
    let byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([byteArray], { type: "image/jpeg" });
    // let blob = new File([this.webcamImage?._imageAsDataUrl], "Webcamimage" , { type: 'image/png' });
    // let blob = new File([this.webcamImage?._imageAsDataUrl], "Webcamimage" , { type: 'image/png' });
    this.uploadDocForm.patchValue({ selfie_holding: blob })
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}

