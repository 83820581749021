import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/api.services';
import { Router } from '@angular/router';
declare let Swiper: any;

@Component({
  selector: 'app-selecthorse',
  templateUrl: './selecthorse.component.html',
  styleUrl: './selecthorse.component.css'
})
export class SelecthorseComponent implements OnInit, AfterViewInit {

  horseDetails: any;

  @ViewChild('swiperContainer', { static: false }) swiperContainer!: ElementRef;

  constructor(private callApi: UserService, private router: Router) { }

  ngOnInit(): void {
    this.getHorseData()
  }

  ngAfterViewInit() {
    var swiper = new Swiper(".mySwiper", {
      spaceBetween: 10,
      slidesPerView: 8,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".mySwiper2", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });
  }

  getHorseData() {
    this.callApi.horseData({}).subscribe((res: any) => {
      this.horseDetails = res?.data
    })
  }

  selectHorse(data: any) {
    this.router.navigate(['/horse-details'], { queryParams: {id:data?._id} })
  }

} 
