import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../services/api.services';
import { Web3Service } from '../../services/web3.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var jQuery: any;


@Component({
  selector: 'app-racedetails',
  templateUrl: './racedetails.component.html',
  styleUrl: './racedetails.component.css',
  encapsulation: ViewEncapsulation.None

})
export class RacedetailsComponent implements OnInit {

  raceDetails: any = []
  activeClass: Number = 0
  raceId!: String;
  expandTable!: Boolean
  hours: number = 0;
  minutes: number = 0;
  second: number = 0;
  endTime: any;
  xpData: any;
  poolAmount: any = [];
  myHorse: any = [];
  adminAddress: any;
  ethPrice: any;
  gatesList: any = []
  gateNumber!: String;
  showQuickStart: boolean = false;
  payload: any = { entry_fee_type: null, gates: [], Distance: [], class: this.activeClass };
  entry_type: any;
  index: any;
  liveData: any = [];
  raceHistory : any;

  constructor(private callApi: UserService, private webService: Web3Service,private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getRaceDetails(0)
    this.route.queryParams.subscribe((res:any)=>{
      jQuery('#'+res?.tab).click()
    })
  }

  getRaceDetails(data: any) {
    this.activeClass = data
    let payload = {}
    if (data == 0) {
      payload = {}
    } else {
      payload = {
        class: data
      }
    }
    this.callApi.raceHorse(payload).subscribe((res: any) => {
      if (res?.success) {
        this.raceDetails = res?.data?.result
        this.adminAddress = res?.data?.admin_wallet;
        this.selectedRace(this.raceDetails[this.index], this.index)
      }
    })
  }

  async selectedRace(data: any, index: any) {
    this.showQuickStart = false
    this.xpData = data?.xp_return[0]
    this.poolAmount = data?.amount_return[0]
    this.ethPrice = data?.entry_fee_eth
    this.entry_type = data?.entry_fee_type
    this.index = index
    this.gatesList = []
    if (this.raceId == data?._id && this.expandTable) {
      this.expandTable = false
      this.hours = 0, this.minutes = 0, this.second = 0
      this.calculatedTime(data?.Registration_end, 'stop')
    } else {
      this.expandTable = true
      this.calculatedTime(data?.Registration_end, 'start')
    }
    this.raceId = data?._id
    let dummyArray: any = []
    await data?.Registerd_horse.map((item: any) => {
      dummyArray.push(item?.gate_no)
    })
    let allGate: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    if (await dummyArray?.length) {
      await dummyArray.map((item: any) => {
        allGate.map((chooseItem: any, index: any) => {
          if (item == chooseItem) {
            allGate.splice(index, 1)
          }
        })
      })
    } else {
      this.gatesList = allGate
    }
    this.gatesList = allGate
    let address = sessionStorage.getItem('address')
    data?.Registerd_horse.map((item: any) => {
      if (address?.toLowerCase() == item?.ownerAddress?.toLowerCase()) {
        this.showQuickStart = true
      }
    })
  }

  x: any

  calculatedTime(date: Date, type: string) {
    this.endTime = date
    if (type != 'stop') {
      this.x = setInterval(() => {
        let endTime = new Date(this.endTime).getTime()
        let nowTime = new Date().getTime()
        let remainingTime = endTime - nowTime
        this.hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
        this.second = Math.floor((remainingTime % (1000 * 60)) / 1000)
        if (this.hours < 0 && this.minutes < 0 && this.second < 0) {
          clearInterval(this.x)
        }
      }, 1000)
    }
    if (type == 'stop') {
      clearInterval(this.x)
    }
  }

  selectHorse(gate: String) {
    if (gate == 'any') {
      this.gateNumber = this.gatesList[0]
    } else {
      this.gateNumber = gate
    }
    this.callApi.startSpin()
    setTimeout(() => {
      let payload: any = {
        wallet_address: sessionStorage.getItem('address')
      }
      this.activeClass == 0 ? '' : payload.class = this.activeClass
      this.callApi.userHorse(payload).subscribe(async (res: any) => {
        if (await res?.success) {
          this.myHorse = res?.data
          this.callApi.stopSpin()
        } else {
          this.callApi.stopSpin()
        }
      })
    }, 1000)
  }

  closePopup() {
    this.myHorse = []
  }

  async joinRace(data: any) {
    this.callApi.startSpin()
    try {
      const contractAddress = await this.webService?.nftMintingD(data?.tempAddress)
      const ownerAddress = await contractAddress?.ownerOf(data?.tokenID)
      let myAddress = sessionStorage.getItem("address")
      if (await ownerAddress.toLowerCase() == myAddress?.toLowerCase()) {
        try {
          let payload: any
          if (await this.entry_type) {
            payload = {
              gate_no: this.gateNumber,
              wallet_address: this.callApi.getAddress(),
              nft_id: data?._id,
              race_id: this.raceId
            }
            if (await this.entry_type == 2) {
              const signer: any = await this.webService.getSigner()
              const transactionData = {
                to: this.adminAddress,
                value: BigInt(this.ethPrice * Math.pow(10, 18))
              }
              const receipt = await signer.sendTransaction(transactionData);
              receipt.wait()
              payload.hash = receipt?.hash
            }
            this.callApi.joinRace(payload).subscribe(async (res: any) => {
              if (await res?.success) {
                this.getRaceDetails(this.activeClass)
                this.callApi.showSuccess(await res?.message)
                this.callApi.stopSpin()
                jQuery('#close-btn').click()
                this.expandTable = false
                // this.selectedRace(this.raceDetails[this.index], this.index)
              } else {
                this.callApi.stopSpin()
              }
            })
          } else {
            this.callApi.stopSpin()
          }
        } catch (error) {
          console.log(error);
          this.callApi.stopSpin()
        }
      } else {
        this.callApi.stopSpin()
        this.callApi.showError("Oops! No horses found on this address")
      }
    } catch (error) {
      console.log(error);
      this.callApi.stopSpin()
    }
  }

  async filterData(entry: any, gate: any, distance: any) {
    await entry != null ? this.payload.entry_fee_type = entry : ''
    if (await gate != null) {
      let dummyUser = false
      this.payload?.gates?.forEach((element: any, index: any) => {
        if (element == gate) {
          this.payload?.gates?.splice(index, 1)
          dummyUser = true
        }
      });
      if (!dummyUser) {
        this.payload?.gates?.push(gate)
      }
    }
    if (await distance != null) {
      let dummyUser = false
      this.payload?.Distance?.forEach((element: any, index: any) => {
        if (element == distance) {
          this.payload?.Distance?.splice(index, 1)
          dummyUser = true
        }
      });
      if (!dummyUser) {
        this.payload?.Distance?.push(distance)
      }
    }
    this.activeClass == 0 ? delete this.payload.class : this.payload.class = this.activeClass
    this.callApi.raceHorse(this.payload).subscribe((res: any) => {
      this.raceDetails = res?.data?.result
      this.adminAddress = res?.data?.admin_wallet;
    })
  }

  getLiveRace() {
    this.callApi.liveRace({}).subscribe((res: any) => {
      this.liveData = res?.data
    })
  }

  startRace(id:any){
    this.router.navigate(['/start-race'],{queryParams:{id:id}})
  }

  completedRace(){
    this.callApi.completedRace().subscribe((res:any)=>{
      this.raceHistory = res?.data
    })
  }

}
