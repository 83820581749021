<section class="py-80">
    <div class="container-fluid ps-md-0 w-50">
        <div class="row text-center">
            <h1 class="fw-bold theme-gradient mb-4 w-f mx-auto">KYC User <span *ngIf="data?.status==2">(Rejected)</span>
            </h1>
            <div class="marquee text-danger" *ngIf="data?.status==2">
                <marquee direction="left"> Reason : {{data?.reason}}</marquee>
            </div>
            <mat-horizontal-stepper #stepper>
                <mat-step [stepControl]="acInfoForm" [editable]="isEditable">
                    <form [formGroup]="acInfoForm" autocomplete="off">
                        <ng-template matStepLabel><strong>Account Information</strong></ng-template>
                        <div class="row">
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="firstName" class="label-text">First Name</label> -->
                                <mat-form-field>
                                    <input maxlength="30" (keypress)="stringValidation($event)" matInput
                                        placeholder="Enter first name" formControlName="first_name"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white">
                                    <mat-error *ngIf="acInfoForm.get('first_name').hasError('required')">First name
                                        Required</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('first_name').hasError('minlength')"> First
                                        name should be atleast 3 characters long!</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('first_name').hasError('maxlength')"> First
                                        name can be atmax 30 characters long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="lastname" class="label-text">Last Name</label> -->
                                <mat-form-field>
                                    <input maxlength="30" (keypress)="stringValidation($event)" matInput
                                        placeholder="Enter last name" formControlName="last_name"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white">
                                    <mat-error *ngIf="acInfoForm.get('last_name').hasError('required')">Last name
                                        Required</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('last_name').hasError('minlength')"> Last name
                                        should be atleast 3 characters long!</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('last_name').hasError('maxlength')"> Last name
                                        can be atmax 30 characters long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="date" class="label-text">Date of Birth</label> -->
                                <mat-form-field>
                                    <input readonly formControlName="date" matInput placeholder="MM/DD/YYYY"
                                        (focus)="dob.open()" [matDatepicker]="dob" [min]="minDate" [max]="maxDate"
                                        [disabled]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control bg-dark border-color py-3 ph-color  text-white">
                                    <mat-datepicker-toggle matIconSuffix [for]="dob"
                                        (focus)="dob?.open()"></mat-datepicker-toggle>
                                    <mat-datepicker #dob></mat-datepicker>
                                    <mat-error>Date of birth is Required</mat-error>
                                </mat-form-field>

                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="city" class="label-text">City</label> -->
                                <mat-form-field>
                                    <input matInput placeholder="Enter city" type="text" maxlength="50"
                                        formControlName="city" (keypress)="validation($event)"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white">
                                    <mat-error *ngIf="acInfoForm.get('city').hasError('required')">City name
                                        Required</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('city').hasError('minlength')"> City name
                                        should be atleast 3 characters long!</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('city').hasError('maxlength')"> City name can
                                        be atmax 50 characters long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="country" class="label-text">Country</label> -->
                                <mat-form-field>
                                    <mat-select (valueChange)="dialCode($event)" formControlName="country" type="text"
                                        name="country" placeholder="Select country"
                                        class="form-control py-3 ph-color  text-white">
                                        <mat-option *ngFor="let data of country" [value]="data?.name"
                                            [disabled]="kyc_verify==2 || kyc_verify==1">{{data?.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>Country Required</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="phoneNumber" class="label-text">Phone Number</label> -->
                                <mat-form-field>
                                    <input (keypress)="numberValidation($event)" minlength="5" maxlength="15" matInput
                                        placeholder="Enter phone number" formControlName="phoneNumber"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white">
                                    <span *ngIf="!dialCodeData" matTextPrefix class="border-end me-2 text-white"> +00
                                        &nbsp;</span>
                                    <span *ngIf="dialCodeData" matTextPrefix class="border-end me-2 text-white">
                                        {{dialCodeData}} &nbsp;</span>
                                    <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('required')">Phone
                                        number Required</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('minlength')"> Phone
                                        number should be atleast 5 numbers long!</mat-error>
                                    <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('maxlength')"> Phone
                                        number can be atmax 15 numbers long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                        </div>
                        <div>
                            <button mat-button type="button" matStepperNext
                                class="btn px-5 py-3 fw-bold btn-theme w-100">Next</button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="idCredentialForm" [editable]="isEditable">
                    <form [formGroup]="idCredentialForm" autocomplete="off">
                        <ng-template matStepLabel><strong>ID Credentials</strong></ng-template>
                        <div class="row">
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="id_type" class="label-text">ID Type</label> -->
                                <mat-form-field>
                                    <mat-select formControlName="id_type" type="text" name="id_type"
                                        placeholder="Select ID" (selectionChange)="idchange($event.value)"
                                        [disabled]="kyc_verify==2 || kyc_verify==1">
                                        <mat-option *ngFor="let data of idTypeList"
                                            [value]="data?.name">{{data?.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>ID type required</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="id_number" class="label-text">ID Number</label> -->
                                <mat-form-field>
                                    <input maxlength="20" (keypress)="userNameValidation($event)" matInput
                                        placeholder="Enter ID number" formControlName="id_number"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white">
                                    <mat-error *ngIf="idCredentialForm.get('id_number').hasError('required')">ID
                                        number Required</mat-error>
                                    <mat-error *ngIf="idCredentialForm.get('id_number').hasError('minlength')"> ID
                                        number should be atleast 5 numbers long!</mat-error>
                                    <mat-error *ngIf="idCredentialForm.get('id_number').hasError('maxlength')"> ID
                                        number can be atmax 20 numbers long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <!-- <div class="mb-3 mb-sm-0"> -->
                                <!-- <label for="address" class="label-text">Address</label> -->
                                <mat-form-field>
                                    <textarea maxlength="150" (keypress)="addressValidators($event)" rows="3" matInput
                                        placeholder="Enter address" formControlName="address"
                                        [readonly]="kyc_verify==2 || kyc_verify==1"
                                        class="form-control py-3 ph-color text-white"></textarea>
                                    <mat-error *ngIf="idCredentialForm.get('address').hasError('required')">Address
                                        Required</mat-error>
                                    <mat-error *ngIf="idCredentialForm.get('address').hasError('minlength')">
                                        Address should be atleast 3 characters long!</mat-error>
                                    <mat-error *ngIf="idCredentialForm.get('address').hasError('maxlength')">
                                        Address can be atmax 150 characters long!</mat-error>
                                </mat-form-field>
                                <!-- </div> -->
                            </div>
                            <div class="col-lg-6">
                                <div *ngIf="!id_type">
                                    <!-- <label for="expiry_date" class="label-text">Expiry Date</label> -->
                                    <mat-form-field>
                                        <input readonly matInput formControlName="expiry_date" (focus)="picker.open()"
                                            [min]="today" [disabled]="kyc_verify==2 || kyc_verify==1"
                                            placeholder="MM/DD/YYYY" [matDatepicker]="picker"
                                            class="form-control py-3 ph-color text-white">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"
                                            (focus)="picker.open()"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error
                                            *ngIf="idCredentialForm.get('expiry_date').hasError('required')">Expiry date
                                            is Required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button mat-button matStepperNext type="button"
                                class="btn px-5 py-3 fw-bold btn-theme w-100 ms-2">Next</button>
                            <button mat-button matStepperPrevious type="button"
                                class="btn px-5 py-3 fw-bold btn-theme w-100">Back</button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="uploadDocForm" [editable]="isEditable">
                    <form [formGroup]="uploadDocForm" autocomplete="off">
                        <ng-template matStepLabel><strong>Id Document</strong></ng-template>
                        <div class="row">
                            <div class="col-lg-6">
                                <h6 class="fw-bold theme-gradient mb-4 w-f">Document Front Image :</h6>
                                <input *ngIf="kyc_verify==0 || kyc_verify==3" (change)="uploadImg($event,1)" type="file"
                                    placeholder="id_front_image" required>
                                <mat-error *ngIf="submitted && f['id_front_image'].errors?.['required']">Front image
                                    Required</mat-error>
                                <div>
                                    <img *ngIf="image?.front" class="front_image img-fluid"
                                        [src]="base_url+image?.front">
                                </div>
                            </div>

                            <div class="col-lg-6 pt-4 pt-lg-0">
                                <h6 class="fw-bold theme-gradient mb-4 w-f">Document Back Image :</h6>
                                <input *ngIf="kyc_verify==0 || kyc_verify==3" (change)="uploadImg($event,2)" type="file"
                                    placeholder="id_back_image" required>
                                <mat-error *ngIf="submitted && f['id_back_image'].errors?.['required']">Back image
                                    Required
                                </mat-error>
                                <div>
                                    <img *ngIf="image?.back" class="back_image img-fluid" [src]="base_url+image?.back">
                                </div>
                            </div>
                            <h6 class="fw-bold theme-gradient mb-4 w-f mx-auto">Selfie with ID</h6>
                            <div class="col-lg-6" *ngIf="!selfieImg">
                                <webcam [height]="300" [width]="300" [trigger]="triggerObservable"
                                    (imageCapture)="handleImage($event)" (initError)="handleInitError($event)">
                                </webcam>
                                <br />
                                <button class="theme-btn-2" (click)="triggerSnapshot();">Take A Snapshot</button>
                            </div>
                            <div class="col-lg-6">
                                <img *ngIf="webcamImage?.imageAsDataUrl" class="img-fluid"
                                    [src]="webcamImage?.imageAsDataUrl" />
                                <img class="img-fluid" *ngIf="selfieImg" [src]="selfieImg">
                            </div>
                        </div>
                        <div class="mt-2">
                            <button mat-button type="button" class="btn px-5 py-3 fw-bold btn-theme w-100 ms-2 d-flex"
                                (click)="submit()" *ngIf="kyc_verify == '0' || kyc_verify == '3'">
                                <i class="loading-icon fa me-2"
                                    [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                                {{button}}
                            </button>
                            <button mat-button matStepperPrevious type="button"
                                class="btn px-5 py-3 fw-bold btn-theme w-100 d-flex">
                                Back
                            </button>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</section>