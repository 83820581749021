import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import * as CryptoJS from "crypto-js";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";


@Injectable({
    providedIn: "root"
})

export class UserService {

    public url = environment.base_url
    public token: any = this.getToken();
    public headers = new HttpHeaders().set('_token', (this.token || null))
    private subject = new BehaviorSubject<any>('');

    constructor(private http: HttpClient, private toastr: ToastrService, private spiner: NgxSpinnerService) { }

    encryptData(data: any) {
        const encrypted = CryptoJS.AES.encrypt(data, environment.encrypt_decrypt_key).toString()
        return encrypted
    }

    decryptData(data: any) {
        const decrypted = CryptoJS.AES.decrypt(data, environment.encrypt_decrypt_key).toString(CryptoJS.enc.Utf8)
        return decrypted
    }

    publishEvent(message: any) {
        this.subject.next(message);
    }

    clearEvent() {
        this.subject.next('');
    }

    subscribeEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    showSuccess(message: any) {
        this.toastr.success(message)
    }

    showError(message: any) {
        this.toastr.error(message)
    }

    showInfo(message: any) {
        this.toastr.info(message);
    }

    closeMessage(message: any) {
        this.toastr.remove(message)
    }

    startSpin() {
        this.spiner.show()
    }

    stopSpin() {
        this.spiner.hide()
    }

    register(payload: any) {
        return this.http.post(this.url + 'user/register', payload)
    }

    login(payload: any) {
        return this.http.post(this.url + 'user/login', payload)
    }

    logOut() {
        return this.http.get(this.url + "user/userLogOut", { headers: this.headers })
    }

    getToken() {
        this.token = sessionStorage.getItem('zed_token');
        this.headers = new HttpHeaders().set('_token', (this.token || false))
        return this.token
    }

    getAddress() {
        return sessionStorage.getItem('address')
    }

    verifyOtp(payload: any) {
        return this.http.post(this.url + 'user/accountverify', payload)
    }

    resendmailotp(payload: any) {
        return this.http.post(this.url + 'user/resendmailotp', payload)
    }

    otpEmailVerification(payload: any) {
        return this.http.post(this.url + 'user/verifytwofaotp', payload, { headers: this.headers })
    }

    checkTfa(payload: any) {
        return this.http.post(this.url + 'user/verifyGoogleAuthentication', payload, { headers: this.headers })
    }

    emailAuthentication() {
        return this.http.get(this.url + 'user/requestmailauthentication', { headers: this.headers })
    }

    tfaStatus() {
        return this.http.get(`${this.url}user/userTwofaDetails`, { headers: this.headers })
    }

    forgetRequest(payload: any) {
        return this.http.post(this.url + 'user/requestforgotpassword', payload)
    }

    forgetOTP(payload: any) {
        return this.http.post(this.url + 'user/forgotpassword', payload)
    }

    // <!-- KYC -->
    getAllCountries() {
        return this.http.get(this.url + 'user/countries', { headers: this.headers })
    }

    getProfile() {
        return this.http.get(this.url + 'user/profile', { headers: this.headers })
    }

    getKycParams() {
        return this.http.get(this.url + 'user/getKycParams', { headers: this.headers })
    }

    submitKyc(payload: any) {
        return this.http.post(this.url + 'user/kyc/create', payload, { headers: this.headers })
    }

    uploadKycImage(payload: any) {
        return this.http.post(this.url + 'user/uploadKycImage', payload, { headers: this.headers })
    }

    // <!-- Profile Image -->

    uploadImgProfile(image: any) {
        return this.http.post(this.url + 'user/uploadProfileImage', image, { headers: this.headers })
    }


    // <!-- Twf Enable -->

    tfaresendmailotp(payload: any) {
        return this.http.get(this.url + 'user/requestmailauthentication', { headers: this.headers })
    }

    disablemailauthentication(payload: any) {
        return this.http.post(this.url + 'user/verifytwofaotp', payload, { headers: this.headers })
    }

    getTfa() {
        return this.http.get(this.url + 'user/requestGoogleAuthentication', { headers: this.headers })
    }

    // <!-- 2fa Request -->

    twofaLostingRequest(payload: any) {
        return this.http.post(this.url + 'user/twofaLostingRequest', payload, { headers: this.headers })
    }

    twofaLostImage(payload: any) {
        return this.http.post(this.url + 'user/twofaLostImage', payload, { headers: this.headers })
    }

    // <!-- Horse Data -->

    horseData(payload: any) {
        return this.http.post(this.url + 'user/getNfts', payload, { headers: this.headers })
    }

    confirmHorse(payload: any) {
        return this.http.post(this.url + 'user/getBuyerDetails', payload, { headers: this.headers })
    }

    userHorse(payload: any) {
        return this.http.post(this.url + 'user/getBuyerNFT', payload, { headers: this.headers })
    }

    raceHorse(payload: any) {
        return this.http.post(this.url + 'user/getRaceList', payload, { headers: this.headers })
    }

    joinRace(payload: any) {
        return this.http.post(this.url + 'user/joinRace', payload, { headers: this.headers })
    }

    liveRace(payload:any){
        return this.http.post(this.url + 'user/liveRaceData',payload, { headers : this.headers })
    }

    completedRace(){
        return this.http.get(this.url + 'user/raceResult',{ headers : this.headers })
    }

    completeLiveRace(payload:any){
        return this.http.post(this.url + 'user/completeRace',payload, { headers : this.headers })
    }
}