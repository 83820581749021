<section class="py-80">
    <div class="container">
        <div class="glass-card">
            <div class="row">
                <div class="col-md-6 col-12 order-md-1 order-sm-2 order-2">
                    <form class="row g-3 mt-3 px-4">
                        <h3 class="text-theme">General</h3>
                        <div>
                            <div class="mb-3">
                                <label for="stable-title" class="form-label text-theme">Name</label>
                                <input type="text" readonly
                                    class="form-control border-secondary bg-secondary btn-block shadow-none text-white"
                                    id="stable-title" placeholder="Stable Name" [value]="data?.userDeatils?.user_name">
                            </div>

                            <div class="mb-3">
                                <label for="stable-title" class="form-label text-theme">Email</label>
                                <input type="email" readonly
                                    class="form-control bg-secondary border-secondary shadow-none text-white"
                                    id="stable-title" placeholder="Enter your email" [value]="data?.userDeatils?.email">
                            </div>

                            <div class="mb-3" *ngIf="data?.userDeatils?.dob">
                                <label for="stable-title" class="form-label text-theme">DOB</label>
                                <input type="text" readonly
                                    class="form-control bg-secondary border-secondary shadow-none text-white"
                                    id="stable-title" placeholder="Username#xxxx" [value]="data?.userDeatils?.dob">
                            </div>

                        </div>
                    </form>
                </div>
                <div class="col-md-6 col-12 pt-5  order-md-2 order-sm-1 order-1">

                    <h3 class="text-center text-theme pt-5">STABLE IMAGE</h3>
                    <div class="d-flex align-items-center justify-content-center pt-3">
                        <div class="flex-shrink-0 position-relative">
                            <div class="profile-plus bg-dark rounded-circle w-25 p-2 position-absolute top-30 start-50">
                                <label for="image">
                                    <i for="image" class="fa-solid fa-plus ps-1 text-light"></i>
                                    <input id="image" type="file" hidden (change)="upload($event)">
                                </label>
                            </div>
                            <img *ngIf="!viewImg" src="./assets/img/user.png" alt="Generic placeholder image"
                                class="img-fluid rounded-circle border border-dark border-3" style="width: 150px;">
                            <img *ngIf="viewImg" [src]="viewImg" alt="Generic placeholder image"
                                class="img-fluid rounded-circle border border-dark border-3" style="width: 150px;">
                        </div>
                    </div>
                </div>
            </div>
            <hr class="ms-4 me-4 border-secondary border-2">

            <!-- 2FA Verification -->

            <div class="row ms-3 me-3 mb-5">
                <div class="col-lg-6">
                    <div class="mt-4 h-100 shadow p-3 bg-secondary text-white rounded-3 night-bg">
                        <div class="">
                            <h4 class="gradient-text-color ">2FA Verification</h4>
                            <div
                                class="d-md-flex justify-content-between align-items-center border-bottom pb-3 pb-md-0 text-center text-md-start">
                                <div class="d-md-flex align-items-center">
                                    <img src="assets/img/google-authentication.png" class="twofa_img">
                                    <div class="">
                                        <h6 class="fw-bold ms-2">Google Authentication</h6>
                                        <p class="twofa_content ms-2">2FA Code will be generated by your Google
                                            Authenticator app
                                            available for both iOS and Android OS.</p>
                                    </div>
                                </div>
                                <div class="">
                                    <div style="cursor: pointer;">
                                        <div class="">
                                            <a class="btn btn-theme" *ngIf="data?.userDeatils?.kyc_verify == '0'"
                                                routerLink="/kyc">Kyc Not Yet Submitted</a>
                                            <a class="btn btn-theme" *ngIf="data?.userDeatils?.kyc_verify == '1'"
                                                routerLink="/kyc">Waiting for KYC Approvel</a>
                                            <a *ngIf="data?.userDeatils?.authentic_status === '0' && data?.userDeatils?.kyc_verify == 2"
                                                routerLink="/2fa" class="btn btn-theme">Enable</a>
                                            <a *ngIf="data?.userDeatils?.authentic_status === '1' "
                                                (click)="enable(disableTFA,'google')" class="btn btn-theme">Disable</a>
                                            <a matTooltip="" *ngIf="data?.userDeatils?.kyc_verify =='3' "
                                                routerLink="/kyc" class="btn btn-theme">KYC Upload Again</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-4 h-100 shadow p-3 bg-secondary text-white rounded-3 night-bg">
                        <h4 class="gradient-text-color ">2FA Verification</h4>
                        <div
                            class="mt-3 d-sm-flex justify-content-between align-items-center text-center text-md-start">
                            <div class="d-sm-flex align-items-center text-center text-md-start">
                                <img src="assets/img/mail-authentication.png" class="twofa_img">
                                <div>
                                    <h6 class="fw-bold ms-2"> Email Authentication</h6>
                                    <p class="twofa_content ms-2">Send 2FA code via Email</p>
                                </div>
                            </div>
                            <div class="">
                                <div
                                    *ngIf="data?.userDeatils?.authentic_status === '0' || data?.userDeatils?.authentic_status === '2'">
                                    <a class="btn btn-theme" *ngIf="data?.userDeatils?.kyc_verify == '0'"
                                        routerLink="/kyc">Kyc Not Yet Submitted </a>
                                    <a class="btn btn-theme" *ngIf="data?.userDeatils?.kyc_verify == '1'"
                                        routerLink="/kyc">Waiting for KYC Approvel</a>
                                    <a *ngIf="data?.userDeatils?.authentic_status == '0' && data?.userDeatils?.kyc_verify == 2"
                                        (click)="getEmailOtp()" class="btn btn-theme btn-sm d-sm-flex"><i
                                            class="loading-icon fa me-2"
                                            [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{button}}</a>
                                    <a matTooltip="" *ngIf="data?.userDeatils?.kyc_verify =='3'" routerLink="/kyc"
                                        class="btn btn-theme btn-sm">KYC Upload Again</a>
                                    <a *ngIf="data?.userDeatils?.authentic_status == '2'"
                                        (click)="enable(disableTFA,'Email')" class="btn btn-theme">Disable</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #disableTFA>
    <div class="mt-3">
        <div>
            <mat-icon class="position-absolute end-0 me-3" [mat-dialog-close]="true" color="warn">close</mat-icon>
            <h4 class="fw-bold theme-gradient w-f mx-auto p-2">Disable {{workmail}} 2FA</h4>
        </div>
        <div class="p-3">
            <div class="row">
                <div class="col-lg-12 mb-3 mt-0">
                    <div class="form-group">
                        <h5 class="fw-bold theme-gradient mb-4 w-f mx-auto">Authenticate Code <span class="maditory_span">*</span></h5>
                        <form [formGroup]="enableForm" *ngIf="workmail == 'Google'" autocomplete="off">
                            <input (keypress)="keyPressNumbers($event)" maxlength="6" name="name" formControlName="otp"
                                    type="text" class="form-control shadow p-2 border rounded-3" placeholder="Enter Code">
                            <a (click)="backEnable(workmail)" class="btn btn-theme mx-auto mt-4 end-0">Disable</a>
                        </form>
                        <form *ngIf="workmail == 'Email'" autocomplete="off">
                            <input name="name" maxlength="6" (keypress)="keyPressNumbers($event)" [(ngModel)]="mailOTP"
                                    type="text" class="form-control bg-dark border-color py-3 ph-color shadow text-white" placeholder="Enter Code">
                            <a (click)="backEnable(workmail)" class="btn btn-theme mx-auto mt-4 end-0 ">Disable</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>