<ngx-spinner type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>
<section class="py-80">
    <div class="container">

        <!-- nav list -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active text-theme fw-medium" id="Event-tab" data-bs-toggle="tab"
                    data-bs-target="#Event-tab-pane" type="button" role="tab" aria-controls="Event-tab-pane"
                    aria-selected="true">Event</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-theme fw-medium" id="Result-tab" data-bs-toggle="tab"
                    data-bs-target="#Result-tab-pane" type="button" role="tab" aria-controls="Result-tab-pane"
                    aria-selected="false" (click)="getLiveRace()">Next to run</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-theme fw-medium" id="Winnings-tab" data-bs-toggle="tab"
                    data-bs-target="#Winnings-tab-pane" type="button" role="tab" aria-controls="Winnings-tab-pane"
                    aria-selected="false" (click)="completedRace()">Result</button>
            </li>
        </ul>

        <!-- nav content -->
        <div class="tab-content" id="myTabContent">

            <!-- event content -->
            <div class="tab-pane fade show active" id="Event-tab-pane" role="tabpanel" aria-labelledby="Event-tab"
                tabindex="0">

                <!-- title -->
                <h2 class="mt-5 text-secondary">RACES OPEN</h2>

                <!-- table option -->
                <div class="row mt-4 ">
                    <div class="col">
                        <button class="btn btn-theme" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample"> Filter</button>
                        <button class="btn btn-class ms-2 me-2" [ngClass]="activeClass==0?'active-class':''"
                            (click)="getRaceDetails(0)">
                            All Class
                        </button>
                        <button class="btn btn-class me-2" [ngClass]="activeClass==6?'active-class':''"
                            (click)="getRaceDetails(6)">
                            Class 6
                        </button>
                        <button class="btn btn-class me-2" [ngClass]="activeClass==5?'active-class':''"
                            (click)="getRaceDetails(5)">
                            Class 5
                        </button>
                        <button class="btn btn-class me-2" [ngClass]="activeClass==4?'active-class':''"
                            (click)="getRaceDetails(4)">
                            Class 4
                        </button>
                        <button class="btn btn-class me-2" [ngClass]="activeClass==3?'active-class':''"
                            (click)="getRaceDetails(3)">
                            Class 3
                        </button>
                        <button class="btn btn-class me-2" [ngClass]="activeClass==2?'active-class':''"
                            (click)="getRaceDetails(2)">
                            Class 2
                        </button>
                        <button class="btn btn-class" [ngClass]="activeClass==1?'active-class':''"
                            (click)="getRaceDetails(1)">
                            Class 1
                        </button>
                    </div>
                    <div class="col-3 text-end">
                        <select class="form-select glass-card rounded shadow-none border-secondary text-theme"
                            aria-label="Default select example">
                            <option selected>sort by</option>
                            <option value="1">hight to low</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>

                <!-- side filter -->
                <div class="offcanvas offcanvas-start bg-dark" tabindex="-1" id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title text-theme" id="offcanvasExampleLabel">Filter</h5>
                        <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">

                        <!-- entry -->
                        <div>
                            <p class="text-theme">ENTRY FEE</p>
                            <ul class="nav nav-pills mb-3 bg-success w-f rounded-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active text-light fw-medium" id="pills-home-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                                        aria-controls="pills-home" aria-selected="true"
                                        (click)="filterData('all',null,null)">All </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link text-light fw-medium" id="pills-profile-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                                        aria-controls="pills-profile" aria-selected="false"
                                        (click)="filterData(2,null,null)">Paid</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link text-light fw-medium" id="pills-contact-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                                        aria-controls="pills-contact" aria-selected="false"
                                        (click)="filterData(1,null,null)">Free</button>
                                </li>
                            </ul>
                        </div>

                        <!-- gate -->
                        <div class="mt-5">
                            <p class="text-theme">NUMBER OF GATE</p>
                            <div class="form-check">
                                <input class="form-check-input bg-success" type="checkbox" value="" id="12"
                                    (click)="filterData(null,12,null)">
                                <label class="form-check-label text-light" for="12">
                                    12
                                </label>
                            </div>
                            <div class="form-check mt-3">
                                <input class="form-check-input bg-success" type="checkbox" value="" id="6"
                                    (click)="filterData(null,6,null)">
                                <label class="form-check-label text-light" for="6">
                                    6
                                </label>
                            </div>
                            <div class="form-check mt-3">
                                <input class="form-check-input bg-success" type="checkbox" value="" id="2"
                                    (click)="filterData(null,2,null)">
                                <label class="form-check-label text-light" for="2">
                                    2
                                </label>
                            </div>
                        </div>

                        <!-- DISTANCE -->
                        <div class="mt-5">
                            <p class="text-theme">DISTANCE</p>

                            <!-- Spinter -->
                            <div>
                                <p class="text-secondary"> Spinter</p>
                                <div class="form-check">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="1000"
                                        (click)="filterData(null,null,1000)">
                                    <label class="form-check-label text-light" for="1000">
                                        1000m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="1200"
                                        (click)="filterData(null,null,1200)">
                                    <label class="form-check-label text-light" for="1200">
                                        1200m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="1400"
                                        (click)="filterData(null,null,1400)">
                                    <label class="form-check-label text-light" for="1400">
                                        1400m
                                    </label>
                                </div>
                            </div>

                            <!-- Midrunner -->
                            <div class="mt-3">
                                <p class="text-secondary"> Midrunner</p>
                                <div class="form-check">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="1600"
                                        (click)="filterData(null,null,1600)">
                                    <label class="form-check-label text-light" for="1600">
                                        1600m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="1800"
                                        (click)="filterData(null,null,1800)">
                                    <label class="form-check-label text-light" for="1800">
                                        1800m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="2000"
                                        (click)="filterData(null,null,2000)">
                                    <label class="form-check-label text-light" for="2000">
                                        2000m
                                    </label>
                                </div>
                            </div>

                            <!-- Marathan -->
                            <div class="mt-3">
                                <p class="text-secondary"> Marathan</p>
                                <div class="form-check">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="2200"
                                        (click)="filterData(null,null,2200)">
                                    <label class="form-check-label text-light" for="2200">
                                        2200m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="2400"
                                        (click)="filterData(null,null,2400)">
                                    <label class="form-check-label text-light" for="2400">
                                        2400m
                                    </label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input bg-success" type="checkbox" value="" id="2600"
                                        (click)="filterData(null,null,2800)">
                                    <label class="form-check-label text-light" for="2600">
                                        2600m
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- apply btn -->
                        <a class="btn btn-theme mt-5 px-4" data-bs-dismiss="offcanvas" aria-label="Close"> Apply</a>
                    </div>
                </div>

                <!-- table -->
                <table
                    class="table mt-4 rounded align-middle table-hover glass-card border-end-0 border-start-0 border-top-0">
                    <thead>
                        <tr class="border-success border-2 border-top-0 border-start-0 border-end-0">
                            <th scope="col" class="py-3 text-theme">Event</th>
                            <th scope="col" class="py-3 text-theme">EVENT TYPE</th>
                            <th scope="col" class="py-3 text-theme">Distance</th>
                            <th scope="col" class="py-3 text-theme">Entry fee</th>
                            <th scope="col" class="py-3 text-theme">Prize pool</th>
                            <th scope="col" class="py-3 text-theme">Gates</th>
                            <!-- <th scope="col" class="col-2"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let data of raceDetails;let i = index">
                            <tr (click)="selectedRace(data,i)"
                                [ngClass]="data?._id == raceId && expandTable?'d-none':''" style="cursor: pointer;">
                                <th scope="row" class="py-4">
                                    <h5 class="text-white">{{data?.track_name}}</h5>
                                    <p class="mb-0 text-theme">{{data?.country_code +' . '+ data?.venue}}</p>
                                </th>
                                <td class="">
                                    <span class="text-white me-2">{{data?.class}}</span>
                                    @if(data?.event_type){
                                    @switch(data?.event_type){
                                    @case (1) {
                                    <span class="mb-0 text-theme" class="top-1">
                                        <svg viewBox="0 0 16 16" focusable="false" width="22px">
                                            <path
                                                d="M8 3.11316L8.3562 3.77625L8.59787 4.22613L9.10401 4.29412L9.72025 4.3769L9.33331 4.72426L8.91515 5.09965L9.01834 5.65204L9.12776 6.23772L8.43549 5.90282L7.99977 5.69204L7.56413 5.90301L6.87511 6.2367L6.98433 5.65204L7.0876 5.09922L6.66887 4.72382L6.28163 4.37665L6.89599 4.29412L7.40213 4.22613L7.6438 3.77625L8 3.11316Z"
                                                fill="currentColor" stroke="currentColor" stroke-width="2"
                                                stroke-miterlimit="10"></path>
                                            <path d="M5 12H11V14H5V12Z" stroke="currentColor" stroke-width="2"></path>
                                            <path d="M7 10H9V12H7V10Z" stroke="currentColor" stroke-width="2"></path>
                                        </svg>
                                        WTA
                                    </span>
                                    }
                                    @case (2) {
                                    <span class="mb-0 text-theme" class="top-3">
                                        <svg viewBox="0 0 24 24" focusable="false" width="22px">
                                            <path fill="currentColor" fill-rule="evenodd"
                                                d="M16 7.25a4.25 4.25 0 1 1-8.5 0 4.25 4.25 0 0 1 8.5 0Zm6 10a4.25 4.25 0 1 1-8.5 0 4.25 4.25 0 0 1 8.5 0ZM6.25 21.5a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5Z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                        Top 3
                                    </span>
                                    }
                                    @case (3) {
                                    <span class="mb-0 text-theme" class="top-6">
                                        <svg viewBox="0 0 20 16" focusable="false" width="22px">
                                            <path d="M3 5a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                            <path d="M13 5a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                            <path d="M8 11a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                            <path d="M8 5a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                            <path d="M3 11a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                            <path d="M13 11a2 2 0 1 1 4 0 2 2 0 1 1-4 0z" fill="currentColor"></path>
                                        </svg>
                                        Top 6
                                    </span>
                                    }
                                    }
                                    }
                                </td>
                                <td class="text-theme fw-medium">{{data?.Distance +'m'}}</td>
                                <td>
                                    @if(data?.entry_fee_type==1){
                                    <span class="text-theme fw-medium">
                                        <img src="../../../assets/img/free.png" height="35px" width="60px">
                                    </span>
                                    } @else {
                                    <span class="text-success fw-medium">
                                        <img src="assets/img/crypto.png" alt="">
                                        {{data?.entry_fee}}
                                    </span>
                                    <span class="text-theme small fw-medium"> USD</span>
                                    }
                                </td>
                                <td>
                                    @if(data?.entry_fee){
                                    <span class="text-success fw-medium"> <img src="assets/img/crypto.png" alt="">
                                        {{data?.entry_fee * data?.gates}}</span>
                                    <span class="text-theme small fw-medium"> USD + XP</span>
                                    } @else {
                                    <span class="text-theme fw-medium">XP</span>
                                    }
                                </td>
                                <td class="text-theme fw-medium">{{data?.gates}}</td>
                            </tr>
                            <tr *ngIf="data?._id == raceId && expandTable">
                                <td colspan="12">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h5>
                                                <span class="text-white">{{data?.track_name}}</span><span
                                                    class="ms-2 text-secondary">{{data?.country_code +' . '+
                                                    data?.venue}} <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="16" fill="currentColor" class="ms-2bi bi-copy"
                                                        viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd"
                                                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                    </svg>
                                                </span>
                                            </h5>
                                            <div class="d-flex align-items-center mt-3">
                                                <h6 class="mb-0 text-white">Event Type</h6>
                                                <div>
                                                    <p class="color-btn-1 mb-0 ms-3">{{data?.class}}</p>
                                                </div>
                                                <div>
                                                    @if(data?.event_type){
                                                    @switch(data?.event_type){
                                                    @case (1) {
                                                    <p class="color-btn-1 mb-0 ms-3">WTA</p>
                                                    }
                                                    @case (2) {
                                                    <p class="color-btn-1 mb-0 ms-3">Top 3</p>
                                                    }
                                                    @case (3) {
                                                    <p class="color-btn-1 mb-0 ms-3">Top 6</p>
                                                    }
                                                    }
                                                    }
                                                </div>
                                                <div>
                                                    <p class="mb-0 ms-3 text-white">Distance <span
                                                            class="ms-1">{{data?.Distance+'m'}}</span></p>
                                                </div>
                                                <div>
                                                    <p class="mb-0 ms-3 text-white">Status <span>Open</span></p>
                                                </div>
                                                <div>
                                                    <p class="mb-0 ms-3 text-white">Registration End : {{hours+'h'}}
                                                        {{minutes+'m'}} {{second+'s'}}</p>
                                                </div>
                                                <div>
                                                    <p class="mb-0 ms-3 text-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            matTooltip="In the event this race doesn't fill and has to be cancelled. All racehorses entered will automatically be returned to their stable and the entry fee credited back to the owner's wallet soon."
                                                            fill="currentColor" class="bi bi-exclamation-circle"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            <path
                                                                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                                        </svg>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div class="text-end" (click)="selectedRace(data,'')">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                        fill="#ff0000" class="bi bi-x" viewBox="0 0 16 16">
                                                        <path
                                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                    </svg>
                                                </div>
                                                <h6 class="text-white">{{data?.entry_fee?'$'
                                                    +data?.entry_fee*data?.gates+'USD + XP':'FREE + XP'}}</h6>
                                                <div class="down-pool position-relative">
                                                    <p class="text-white">Break Down Pool
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="ms-1 bi bi-caret-down-fill"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                        </svg>
                                                    </p>
                                                    <div class="down-pool-inner ">
                                                        <div
                                                            class="p-3  bg-img glass-card border-end-0 border-start-0 border-top-0">
                                                            <ul class="list-unstyled">
                                                                <li>
                                                                    <p class="text-white"><span>1st</span> :<span
                                                                            *ngIf="poolAmount?.one">${{poolAmount?.one|number:'0.0-0'}}
                                                                            USD</span> {{xpData?.one +'XP'}}</p>
                                                                </li>
                                                                <li>
                                                                    <p class="text-white"><span>2nd</span> :<span
                                                                            *ngIf="poolAmount?.two">${{poolAmount?.two|number:'0.0-0'}}
                                                                            USD</span> {{xpData?.two +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>2">
                                                                    <p class="text-white"><span>3rd</span> :<span
                                                                            *ngIf="poolAmount?.three">${{poolAmount?.three|number:'0.0-0'}}
                                                                            USD</span> {{ xpData?.three +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>2">
                                                                    <p class="text-white"><span>4th</span> :<span
                                                                            *ngIf="poolAmount?.four">${{poolAmount?.four|number:'0.0-0'}}
                                                                            USD</span> {{xpData?.four +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>2">
                                                                    <p class="text-white"><span>5th</span> :<span
                                                                            *ngIf="poolAmount?.five">${{poolAmount?.five|number:'0.0-0'}}
                                                                            USD</span> {{ xpData?.five +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>2">
                                                                    <p class="text-white"><span>6th</span> : <span
                                                                            *ngIf="poolAmount?.six">${{poolAmount?.six|number:'0.0-0'}}
                                                                            USD</span> {{xpData?.six +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>7th</span> : {{
                                                                        xpData?.seven +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>8th</span> :
                                                                        {{xpData?.eight +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>9th</span> : {{
                                                                        xpData?.nine +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>10th</span> : {{
                                                                        xpData?.ten +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>11th</span> : {{
                                                                        xpData?.eleven +'XP'}}</p>
                                                                </li>
                                                                <li *ngIf="data?.gates>6">
                                                                    <p class="text-white"><span>12th</span> : {{
                                                                        xpData?.twelve +'XP'}}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table
                                        class="table mt-4 rounded align-middle table-hover glass-card border-end-0 border-start-0 border-top-0">
                                        <thead>
                                            <tr
                                                class="border-success border-2 border-top-0 border-start-0 border-end-0">
                                                <th scope="col" class="py-3 text-theme">GATE
                                                </th>
                                                <th scope="col" class="py-3 text-theme">RACEHORSE
                                                </th>
                                                <th scope="col" class="py-3 text-theme">DETAILS
                                                </th>
                                                <th scope="col" class="py-3 text-theme">CAREER
                                                </th>
                                                <th scope="col" class="py-3 text-theme">STABLE
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @if(data?.Registerd_horse?.length){
                                            @for(details of data?.Registerd_horse;track details){
                                            <tr>
                                                <td class="text-theme fw-medium race-horse-list">
                                                    <div class="d-flex justify-content-between">
                                                        {{details?.gate_no}}
                                                        <img [src]="details?.image" alt="" class="img-fluid" width="70">
                                                    </div>
                                                </td>
                                                <td class="text-theme fw-medium">{{details?.horsename}}</td>
                                                <td class="text-theme fw-medium">Z15.Buterin</td>
                                                <td class="text-theme fw-medium">0/0/0</td>
                                                <td class="text-theme fw-medium">{{details?.user_name}}</td>
                                            </tr>
                                            }
                                            }
                                        </tbody>
                                    </table>
                                    @if(!data?.Registerd_horse?.length) {
                                    <div class="text-center text-white p-4">No race horse is found</div>
                                    }
                                    <div class="d-flex justify-content-center">
                                        <div class="quick-start-box p-4">
                                            <div class="quick-start-box-content" *ngIf="showQuickStart">
                                                <p class="text-white fs-5">You are already in this race.</p>
                                            </div>
                                            <div class="quick-start-box-content"
                                                *ngIf="!showQuickStart && gatesList[0] >= data?.gates">
                                                <p class="text-white fs-5">Gates are filled.</p>
                                            </div>
                                            <div class="quick-start-box-content"
                                                *ngIf="!showQuickStart && gatesList[0] <= data?.gates">
                                                <p class="text-white fs-5">Pick a gate and enter a racehorse</p>
                                                <button data-bs-toggle="modal" data-bs-target="#femalehorse"
                                                    (click)="selectHorse('any')">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16"
                                                        fill="#fff" class="bi bi-clock-history" class="mb-1"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                                                        <path
                                                            d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                                        <path
                                                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    Quick Enter
                                                </button>
                                                <p class="text-white mt-3">Note: <b>Quick Enter</b> selects any gate
                                                    number for you for increased chances of reserving a gate.</p>
                                                <p class="text-white text-center fw-bold">Open Gates</p>
                                                <div class="d-flex justify-content-center">
                                                    <div class="quick-start-box-gates ms-4">
                                                        @for(gateData of gatesList;track gateData){
                                                        <div data-bs-toggle="modal" data-bs-target="#femalehorse"
                                                            (click)="selectHorse(gateData)"
                                                            *ngIf="data?.gates >= gateData">
                                                            {{gateData}}
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div *ngIf="!raceDetails?.length" class="text-center mt-5 position-relative">
                    <img src="../../../assets/img/no-record.png" class="img-fluid" width="100">
                    <div class="no-record-found mt-1">
                        No events found
                    </div>
                </div>
            </div>

            <!-- result content -->
            <div class="tab-pane fade" id="Result-tab-pane" role="tabpanel" aria-labelledby="Result-tab" tabindex="0">

                <!-- table option -->
                <div class="row mt-5 align-items-center">
                    <div class="col">
                        <h2 class="text-secondary">RESULTS</h2>
                    </div>
                    <div class="col-auto text-end" *ngIf="liveData?.length>0">
                        <div class="bg-dark p-3 rounded-3 text-center">
                            <span class="text-theme fw-bold">TOTAL RACE : </span> <span
                                class="text-theme fw-bold">{{liveData?.length}}</span>
                        </div>
                    </div>
                </div>

                <!-- table -->
                <table
                    class="table mt-4 rounded align-middle table-hover glass-card border-end-0 border-start-0 border-top-0 text-center">
                    <thead>
                        <tr class="border-success border-2 border-top-0 border-start-0 border-end-0">
                            <th scope="col" class="py-3 text-theme">Event</th>
                            <th scope="col" class="py-3 text-theme">Distance</th>
                            <th scope="col" class="py-3 text-theme">Status</th>
                            <th scope="col" class="py-3 text-theme">Run At</th>
                            <th scope="col" class="py-3 text-theme">View Race</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for(data of liveData;track data){
                        <tr>
                            <th scope="row" class="py-4">
                                <h5 class="text-white">{{data?.track_name}}</h5>
                                <p class="mb-0 text-theme">{{data?.country_code +'.'+ data?.venue}}</p>
                            </th>
                            <td class="text-theme fw-medium">{{data?.Distance+'m'}}</td>
                            <td>
                                <span class="text-warning fw-medium">Live</span>
                            </td>
                            <td>
                                <span class="text-theme fw-medium">{{data?.start_time|date:'short'}}</span>
                            </td>
                            <td>
                                <a (click)="startRace(data?._id)" class="btn btn-theme"> Live</a>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
                <div *ngIf="!liveData?.length" class="text-center mt-5 position-relative">
                    <img src="../../../assets/img/no-record.png" class="img-fluid" width="100">
                    <div class="no-record-found mt-1">
                        No events found
                    </div>
                </div>
            </div>

            <!-- winnings content -->
            <div class="tab-pane fade" id="Winnings-tab-pane" role="tabpanel" aria-labelledby="Winnings-tab"
                tabindex="0">

                <!-- table option -->
                <div class="row mt-5 align-items-center">
                    <div class="col">
                        <h2 class="text-secondary">WINNINGS</h2>
                    </div>
                    <div class="col-auto text-end">
                        <div class="bg-dark p-3 rounded-3 text-center">
                            <span class="text-success fs-5 fw-bold">$ {{raceHistory?.totalamount}} USD</span> <span
                                class="text-theme fw-bold ms-2">TOTAL WON</span>
                        </div>
                    </div>
                </div>

                <!-- table -->
                <table
                    class="table mt-4 rounded align-middle table-hover glass-card border-end-0 border-start-0 border-top-0">
                    <thead>
                        <tr class="border-success border-2 border-top-0 border-start-0 border-end-0">
                            <th scope="col" class="py-3 text-theme">Event</th>
                            <th scope="col" class="py-3 text-theme">Horse</th>
                            <th scope="col" class="py-3 text-theme">Distance</th>
                            <th scope="col" class="py-3 text-theme">Status</th>
                            <th scope="col" class="py-3 text-theme">Run At</th>
                            <th scope="col" class="py-3 text-theme">Place</th>
                            <!-- <th scope="col" class="py-3 text-theme">View Race</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        @for(data of raceHistory?.result;track data){
                        <tr>
                            <th class="py-4">
                                <div class="d-flex">
                                    <div>
                                        <h5 class="text-white">{{data?.track_name}}</h5>
                                        <p class="mb-0 text-theme">{{data?.country_code +'.'+ data?.venue}}</p>
                                    </div>
                                    <div class="ps-2">
                                        <img [src]="data?.image" height="50px">
                                    </div>
                                </div>
                            </th>
                            <td class="text-theme fw-medium">{{data?.horsename}}</td>
                            <td class="text-theme fw-medium">{{data?.Distance+'m'}}</td>
                            <td class="text-theme fw-medium">Finised</td>
                            <td class="text-theme fw-medium">{{data?.start_time|date:'short'}}</td>
                            <td>
                                <span class="p-2 rounded-2"
                                    [ngClass]="data?.position=='one'?'bg-gold':data?.position=='two'?'bg-silver':data?.position=='three'?'bg-brown':'bg-secondary'">
                                    {{data?.position == 'one' ? '1' : data?.position == 'two' ? '2' : data?.position ==
                                    'three' ? '3' :data?.position == 'four' ? '4':
                                    data?.position == 'five' ? '5' : data?.position == 'six' ? '6' : data?.position ==
                                    'seven' ? '7' : data?.position == 'eight' ? '8':
                                    data?.position == 'nine' ? '9' : data?.position == 'ten' ? '10' : data?.position ==
                                    'eleven' ? '11' : '12'}}
                                </span>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
                <div *ngIf="!raceHistory?.result?.length" class="text-center mt-5 position-relative">
                    <img src="../../../assets/img/no-record.png" class="img-fluid" width="100">
                    <div class="no-record-found mt-1">
                        No events found
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="femalehorse" tabindex="-1" aria-labelledby="femalehorselabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content  bg-dark">
            <div class="modal-header border-0">
                <h1 class="modal-title fs-5 text-white" id="exampleModalLabel"></h1>
                <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" id="close-btn"
                    aria-label="Close" (click)="closePopup()"></button>
            </div>
            <div class="modal-body">
                <!-- my stable LIST -->
                <section class="">
                    <div class="container">
                        <h2 class="text-theme mb-0">Select Racehorse</h2>
                        <hr class="border-white">
                        <div class="glass-card p-4 mt-4" *ngFor="let data of myHorse">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="d-flex">
                                        <img [src]="data?.image_url" class="me-4 border bg-dark border-secondary"
                                            width="128" alt="">
                                        <div class="w-100">
                                            <h5 class="fw-bold text-white mb-0 fs-4">{{data?.name}}<a href=""><img
                                                        src="" alt=""></a></h5>
                                            <ul class="text-theme list-unstyled mt-2">
                                                <li> Bloodline : <span class="text-white"> Uchika</span></li>
                                                <li> Gender : <span class="text-white"> {{data?.gender}}</span></li>
                                                <li> Coat : <span class="text-white"> Classic</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-end">
                                    <a (click)="joinRace(data)" class="btn px-5 py-2 fw-bold btn-theme">Select</a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 text-center">
                            <a routerLink="/dashboard" data-bs-dismiss="modal"
                                class="btn px-5 py-2 fw-bold btn-theme">Go to stable</a>
                            <p class="text-theme mt-3">Purchase more racehorse</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>