import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './common/landing/landing.component';
import { LayoutComponent } from './common/layout/layout.component';
import { LoginComponent } from './common/login/login.component';
import { RegisterComponent } from './common/register/register.component';
import { ForgetpasswordComponent } from './common/forgetpassword/forgetpassword.component';
import { SelecthorseComponent } from './component/selecthorse/selecthorse.component';
import { HorsedetailsComponent } from './component/horsedetails/horsedetails.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OwnhorsedetailsComponent } from './component/ownhorsedetails/ownhorsedetails.component';
import { SettingsComponent } from './component/settings/settings.component';
import { RacedetailsComponent } from './component/racedetails/racedetails.component';
import { StartraceComponent } from './component/startrace/startrace.component';
import { HorsebreedComponent } from './component/horsebreed/horsebreed.component';
import { SelectbreedpairComponent } from './component/selectbreedpair/selectbreedpair.component';
import { StartbreedComponent } from './component/startbreed/startbreed.component';
import { VerifyOtpComponent } from './common/verify-otp/verify-otp.component';
import { TfaverificationComponent } from './common/tfaverification/tfaverification.component';
import { KycuserComponent } from './common/kycuser/kycuser.component';
import { EmailotpComponent } from './common/emailotp/emailotp.component';
import { UsertwofaComponent } from './common/usertwofa/usertwofa.component';
import { TfalostingComponent } from './common/tfalosting/tfalosting.component';
import { AfterLoginAuthGuard, BeforeLoginAuthGuard } from './guard/auth-check.guard';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: LandingComponent },
      { path: 'login', component: LoginComponent, canActivate: [BeforeLoginAuthGuard] },
      { path: 'otp-verification', component: VerifyOtpComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'tfa_verification', component: TfaverificationComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [BeforeLoginAuthGuard] },
      { path: 'forgotpassword', component: ForgetpasswordComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'kyc', component: KycuserComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'choose-horse', component: SelecthorseComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'horse-details', component: HorsedetailsComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'view-detail', component: OwnhorsedetailsComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'race-details', component: RacedetailsComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'start-race', component: StartraceComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'breed', component: HorsebreedComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'select-breed', component: SelectbreedpairComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'start-breed', component: StartbreedComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'email-otp', component: EmailotpComponent, canActivate: [AfterLoginAuthGuard] },
      { path: '2fa', component: UsertwofaComponent, canActivate: [AfterLoginAuthGuard] },
      { path: 'tfa-losting', component: TfalostingComponent, canActivate: [AfterLoginAuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
