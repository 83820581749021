<header class="container-fluid">
    <div class="row align-items-center justify-content-center">
        <div class="col-auto order-1" style="cursor: pointer;">
            <img src="assets/img/logo-white.png" class="img-fluid" alt="" routerLink="">
        </div>
        <div class="col-lg-auto  order-lg-2 order-4" *ngIf="token">
            <div class="d-flex justify-content-lg-start justify-content-center py-3">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a routerLink="/dashboard" class="nav-link text-theme bg-transparent fw-medium active"
                            aria-current="page">
                            MY STABLE
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/race-details" class="nav-link text-theme bg-transparent fw-medium">
                            RACE
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/breed" class="nav-link text-theme bg-transparent fw-medium ">
                            BREED
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/choose-horse" class="nav-link text-theme bg-transparent fw-medium">
                            BUY
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-auto order-lg-3 order-2 ms-sm-auto">
            <a class="btn px-sm-4 p-2 py-1 fw-bold btn-theme rounded-pill"
                routerLink="/login" *ngIf="!token">
                Login
            </a>
            <a class="btn px-sm-4 p-2 py-1 fw-bold btn-theme rounded-pill ms-2" 
                routerLink="/register" *ngIf="!token">
                Register
            </a>
        </div>
        <div class="col-auto order-lg-4 order-3 text-end" *ngIf="token">
            <div class="dropdown">
                <img *ngIf="!profile_image" src="assets/img/profile.png" width="48" class="me-sm-4" alt="profile" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <img *ngIf="profile_image" [src]="profile_image" width="48" class="me-sm-4" alt="profile" data-bs-toggle="dropdown"
                    aria-expanded="false">
                <ul class="dropdown-menu  dropdown-menu-end dropdown-menu-dark">
                    <li>
                        <a class="dropdown-item" routerLink="/settings"> <img src="assets/img/settings.png" class="me-2"
                                alt=""> Setting</a>
                    </li>
                    <li class="my-2">
                        <a class="dropdown-item" routerLink="/dashboard"><img src="assets/img/horse.png" class="me-2"
                                alt=""> My stable</a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logOut()"><img src="assets/img/power.png" class="me-2"
                                alt=""> Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>