import { Component, OnInit } from '@angular/core';
import { Web3Service } from '../../services/web3.service';
import { UserService } from '../../services/api.services';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-horsedetails',
  templateUrl: './horsedetails.component.html',
  styleUrl: './horsedetails.component.css'
})
export class HorsedetailsComponent implements OnInit {


  horseData: any = {};

  constructor(private web3Service: Web3Service, private callApi: UserService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((res: any) => {
      this.callApi.horseData({ id: res?.id }).subscribe((res: any) => {
        this.horseData = res?.data
      })
    })

  }

  async buyHorse() {
    Swal.fire({
      title: 'Are you ready to buy this NFT?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result?.isConfirmed) {
        this.web3Service.openMetamask().then(async (res) => {
          if (res) {
            this.callApi.startSpin()
            let contractMethods = await this.web3Service.nftMintingD(this.horseData?.tempAddress)
            try {
              const options = { value: this.horseData?.amount }
              let signIn = await contractMethods?.buyDirect(this.horseData?.name, this.horseData?.symbol, this.horseData?.tokenID, options)
              await signIn.wait();
              let payload = {
                _id: this.horseData?._id,
                wallet_address: this.callApi.getAddress(),
                hash: signIn?.hash
              }
              this.callApi.confirmHorse(payload).subscribe((res: any) => {
                if (res?.success) {
                  this.callApi.stopSpin()
                  this.callApi.showSuccess(res?.message)
                  this.router.navigate(['/dashboard'])
                } else {
                  this.callApi.stopSpin()
                  this.callApi.showError(res?.message)
                }
                // Api is just for confirmation the Nft is Yours after buy nft
              })
            } catch (error: any) {
              this.callApi.showError(error?.reason)
              this.callApi.stopSpin()
            }
          }
        })
      }
    })
  }

}
