import { Component } from '@angular/core';

@Component({
  selector: 'app-horsebreed',
  templateUrl: './horsebreed.component.html',
  styleUrl: './horsebreed.component.css'
})
export class HorsebreedComponent {

}
